import { Card, Group, LoadingOverlay, Skeleton, Text, ThemeIcon } from '@mantine/core'
import { IconTruck } from '@tabler/icons-react'

import { useVehicle } from '../hooks/useVehicle'

export const SelectedVehicleCard = ({ vehicleId }: { vehicleId?: string }) => {
  const { data: vehicleData, isLoading: isVehicleDataLoading } = useVehicle(vehicleId)

  if (!vehicleId) {
    return (
      <Card withBorder p={'xs'} miw={250}>
        <Text>No vehicle selected</Text>
      </Card>
    )
  }

  return (
    <Card withBorder p={'xs'} miw={250}>
      <LoadingOverlay visible={isVehicleDataLoading} loaderProps={{ size: 'sm' }} />
      <Group gap={'sm'} wrap="nowrap">
        <ThemeIcon>
          <IconTruck />
        </ThemeIcon>
        <Group gap={4}>
          <Text size={'md'} truncate="end" fw={700} component="span">
            {vehicleId ? vehicleData ? vehicleData.name : <Skeleton width={80} height={10} /> : 'No vehicle selected'}
          </Text>
          <Text size={'md'} truncate="end" component="span">
            {vehicleId ? (
              <>
                {vehicleData ? (
                  `${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`
                ) : (
                  <Skeleton width={120} height={8} mt={6} />
                )}
              </>
            ) : null}
          </Text>
        </Group>
      </Group>
    </Card>
  )
}
