import 'react-datepicker/dist/react-datepicker.css'

import { Group, Paper, Stack, Title } from '@mantine/core'
import { SnackbarProvider } from 'notistack'
import React from 'react'

import { ManualRequest, RequestOfferFileUpload } from './formTypes'
import ManualRequestConfirm from './steps/ManualRequestConfirm'
import ManualRequestDate from './steps/ManualRequestDate'
import ManualRequestProvider from './steps/ManualRequestProvider'
import ManualRequestServices from './steps/ManualRequestServices'
import ManualRequestVehicle from './steps/ManualRequestVehicle'
import OfferFiles from './steps/OfferFiles'
import OfferMeta from './steps/OfferMeta'
import RequestOfferSuccess from './steps/RequestOfferSuccess'

const manualMobileServiceStep = [
  'vehicle',
  'provider',
  'date',
  'offerMeta',
  'offerServices',
  'offerFiles',
  'confirm',
  'success',
] as const
type ManualMobileServiceStep = (typeof manualMobileServiceStep)[number]
type ManualMobileServiceState = Partial<ManualRequest> & {
  step: ManualMobileServiceStep
  completedRequestPermalink?: string
}

const themeUpdates = {
  '--mantine-font-size-xs': 'calc(0.875rem * var(--mantine-scale))',
  '--mantine-font-size-sm': 'calc(1rem * var(--mantine-scale))',
  '--mantine-font-size-md': 'calc(1.125rem * var(--mantine-scale))',
  '--mantine-font-size-lg': 'calc(1.25rem * var(--mantine-scale))',
  '--mantine-font-size-xl': 'calc(1.375rem * var(--mantine-scale))',

  '--mantine-h1-font-size': 'calc(2.825rem * var(--mantine-scale))',
  '--mantine-h1-lineHeight': '1.3',
  '--mantine-h2-font-size': 'calc(2.125rem * var(--mantine-scale))',
  '--mantine-h2-lineHeight': '1.3',
  '--mantine-h3-font-size': 'calc(1.625rem * var(--mantine-scale))',
  '--mantine-h3-lineHeight': '1.35',
  '--mantine-h4-font-size': 'calc(1.375rem * var(--mantine-scale))',
  '--mantine-h4-lineHeight': '1.4',
  '--mantine-h5-font-size': 'calc(1.125rem * var(--mantine-scale))',
  '--mantine-h5-lineHeight': '1.45',
  '--mantine-h6-font-size': 'calc(1rem * var(--mantine-scale))',
  '--mantine-h6-lineHeight': '1.5',
}

type UpdateRequestData =
  | {
      type: 'UPDATE_REQUEST'
      update: Partial<ManualRequest>
    }
  | {
      type: 'NEXT' | 'BACK'
    }
  | {
      type: 'UPDATE_PERMALINK'
      completedRequestPermalink: string
    }
  | {
      type: 'RESTART'
    }

const emptyManualRequest = {
  vehicleId: undefined,
  providerBranchId: undefined,
  date: undefined,
  details: undefined,
  files: undefined,
  inMileage: undefined,
  invoiceNumber: undefined,
  supplyFee: undefined,
  tax: undefined,
  isMobileRequest: undefined,
}

const updateRequestReducer = (state: ManualMobileServiceState, action: UpdateRequestData): ManualMobileServiceState => {
  switch (action.type) {
    case 'UPDATE_REQUEST':
      return {
        ...state,
        ...action.update,
      }
    case 'NEXT':
      return {
        ...state,
        step: getNextStep(state.step),
      }
    case 'BACK':
      return {
        ...state,
        completedRequestPermalink: undefined,
        step: getPreviousStep(state.step),
      }
    case 'UPDATE_PERMALINK':
      return {
        ...state,
        completedRequestPermalink: action.completedRequestPermalink,
      }
    case 'RESTART':
      return {
        ...emptyManualRequest,
        completedRequestPermalink: undefined,
        step: 'vehicle',
      }
    default:
      return state
  }
}

const getNextStep = (currentStep: ManualMobileServiceStep): ManualMobileServiceStep => {
  const currentIndex = manualMobileServiceStep.indexOf(currentStep)
  return manualMobileServiceStep[currentIndex + 1] || currentStep
}

const getPreviousStep = (currentStep: ManualMobileServiceStep): ManualMobileServiceStep => {
  const currentIndex = manualMobileServiceStep.indexOf(currentStep)
  return manualMobileServiceStep[currentIndex - 1] || currentStep
}

const ServiceHistoryUploadPage: React.FC = () => {
  const [state, dispatch] = React.useReducer(updateRequestReducer, {
    ...emptyManualRequest,
    step: 'vehicle',
  })

  return (
    <Group>
      <Stack w="100%" style={{ flex: 1, ...themeUpdates }} h="100vh">
        <Group pl="37px" pt="18px">
          <Title order={2} fw="600" style={{ fontSize: '1.625rem' }}>
            Service history upload
          </Title>
        </Group>
        <Paper w="100%" flex={1}>
          {state.step === 'vehicle' && (
            <ManualRequestVehicle
              vehicleId={state.vehicleId}
              onNext={(vehicleId: string) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { vehicleId } })
                dispatch({ type: 'NEXT' })
              }}
              onBack={() => dispatch({ type: 'BACK' })}
            />
          )}
          {state.step === 'provider' && (
            <ManualRequestProvider
              providerBranchId={state.providerBranchId}
              onNext={(providerBranchId: string) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { providerBranchId } })
                dispatch({ type: 'NEXT' })
              }}
              onBack={() => dispatch({ type: 'BACK' })}
            />
          )}
          {state.step === 'date' && (
            <ManualRequestDate
              date={state.date}
              onNext={(date: Date) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { date } })
                dispatch({ type: 'NEXT' })
              }}
              onBack={() => dispatch({ type: 'BACK' })}
            />
          )}
          {state.step === 'offerMeta' && (
            <OfferMeta
              onNext={(values) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { ...values } })
                dispatch({ type: 'NEXT' })
              }}
              onBack={() => dispatch({ type: 'BACK' })}
              inMileage={state.inMileage}
              invoiceNumber={state.invoiceNumber}
              supplyFee={state.supplyFee}
              tax={state.tax}
              isMobileRequest={state.isMobileRequest}
            />
          )}
          {state.step === 'offerServices' && (
            <ManualRequestServices
              services={state.details}
              onNext={(services) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { details: services } })
                dispatch({ type: 'NEXT' })
              }}
              onBack={() => dispatch({ type: 'BACK' })}
            />
          )}
          {state.step === 'offerFiles' && (
            <OfferFiles
              files={state.files}
              onBack={() => dispatch({ type: 'BACK' })}
              onNext={({ fileObjects }: { fileObjects: RequestOfferFileUpload[] }) => {
                dispatch({ type: 'UPDATE_REQUEST', update: { files: fileObjects } })
                dispatch({ type: 'NEXT' })
              }}
            />
          )}
          {state.step === 'confirm' && (
            <ManualRequestConfirm
              onBack={() => dispatch({ type: 'BACK' })}
              onNext={(completedRequestPermalink) => {
                dispatch({ type: 'UPDATE_PERMALINK', completedRequestPermalink })
                dispatch({ type: 'NEXT' })
              }}
              state={state as ManualRequest}
            />
          )}
          {state.step === 'success' && (
            <RequestOfferSuccess
              onBack={() => dispatch({ type: 'BACK' })}
              onRestart={() => dispatch({ type: 'RESTART' })}
              completedRequestPermalink={state.completedRequestPermalink}
            />
          )}
        </Paper>
      </Stack>
    </Group>
  )
}

export default ServiceHistoryUploadPage
