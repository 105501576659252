import 'react-datepicker/dist/react-datepicker.css'

import { Box as MantineBox, Text, Title } from '@mantine/core'
import {
  Box,
  Container,
  IconButton,
  IconButtonProps,
  Link,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'

import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import { ReactComponent as PdfIcon } from '../../assets/images/pdf.svg'
import { apiCall } from '../../components/api/api'
import socket from '../../sockets/socket'
import { formatDateTime } from '../requests/requests'
import InvoiceReportModal from './modals/invoice_report'
import { ConsumerRequestOffer, InvoiceReport } from './models/invoice_report'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ff6600',
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface StatusButtonProps extends IconButtonProps {
  status: string
}

const StatusButton = styled(IconButton)<StatusButtonProps>(({ theme, status }) => ({
  borderRadius: '20px',
  color: status === 'approved' ? '#067647' : status === 'waiting' ? '#B54708' : '#344054',
  padding: theme.spacing(1, 2),
  fontSize: '0.875rem',
  fontWeight: 'bold',
  textTransform: 'none',
  border: '1px solid transparent',
  marginLeft: 'auto',
  width: 'fit-content',
  '&:hover': {
    backgroundColor: status === 'approved' ? '#ABEFC6' : status === 'waiting' ? '#FEDF89' : '#EAECF0',
    border: '1px solid #fff',
  },
  ...(status === 'approved' && {
    backgroundColor: '#ECFDF3',
    border: '1px solid #ABEFC6',
  }),
  ...(status === 'waiting' && {
    backgroundColor: '#FFFAEB',
    border: '1px solid #FEDF89',
  }),
  ...(status === 'rejected' && {
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
  }),
}))

const InvoiceReviewPage: React.FC = () => {
  const [data, setData] = useState<InvoiceReport[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [totalRows, setTotalRows] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedOffer, setSelectedOffer] = useState<ConsumerRequestOffer | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/offer-invoice?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent('')}`,
        'get',
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    fetchData()

    socket.on('new-invoice-file', fetchData)

    return () => {
      socket.off('new-invoice-file', fetchData)
    }
  }, [fetchData])

  const fetchReportDetails = async (offerId: string) => {
    const url = `admin/offer-invoice/${offerId}`
    try {
      const response = await apiCall(url, 'get')
      setSelectedOffer(response)
      setModalOpen(true)
    } catch (error) {
      console.error('Failed to fetch report details:', error)
      enqueueSnackbar('Error fetching report details', { variant: 'error' })
    }
  }

  const handleOpenModal = (report: InvoiceReport) => {
    if (report.is_approved === null) {
      fetchReportDetails(report.consumer_request_offer_id)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '20px',
        marginBottom: '20px',
        marginTop: '20px',
      }}>
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
            fontFamily: 'var(--mantine-font-family-headings)',
          }}>
          <Title order={2} fw="600">
            Invoice review
          </Title>
        </Box>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Request ID</StyledTableCell>
                  <StyledTableCell>Vehicle ID</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>Shop name</StyledTableCell>
                  <StyledTableCell>Received on</StyledTableCell>
                  <StyledTableCell>Diagnostic report by shop</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((report) => {
                  return (
                    <StyledTableRow key={report.consumer_request_offer_id}>
                      <StyledTableCell>{report.offer.consumer_request.slug}</StyledTableCell>
                      <StyledTableCell>
                        <MantineBox w={150}>
                          <Text size="sm" truncate={'end'}>
                            {report.offer.consumer_request.vehicle_name}
                          </Text>
                        </MantineBox>
                      </StyledTableCell>
                      <StyledTableCell>{report.offer.consumer_request.location_name}</StyledTableCell>
                      <StyledTableCell>{report.offer.provider_branch.name}</StyledTableCell>
                      <StyledTableCell>{formatDateTime(report.updated_at ?? report.created_at)}</StyledTableCell>
                      <StyledTableCell style={{ display: 'flex' }}>
                        <PdfIcon />
                        <Typography
                          sx={{
                            ml: 1,
                            fontSize: '14px',
                            mt: 1,
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {report.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ alignContent: 'left' }}>
                        <Link
                          href={report.url}
                          download
                          target="_blank"
                          sx={{
                            fontWeight: 'bold',
                            color: '#DB5D08',
                            textDecoration: 'none',
                          }}>
                          <DownloadIcon /> Download
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell>
                        <StatusButton
                          status={
                            report.is_approved ? 'approved' : report.is_approved === null ? 'waiting' : 'rejected'
                          }
                          onClick={() => handleOpenModal(report)}
                          sx={{
                            cursor: report.is_approved === null ? 'pointer' : 'default',
                            pointerEvents: report.is_approved === null ? 'auto' : 'none',
                          }}>
                          {report.is_approved
                            ? 'Approved'
                            : report.is_approved === null
                              ? 'Waiting for approval'
                              : 'Sent back to shop'}
                        </StatusButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <InvoiceReportModal open={modalOpen} onClose={handleCloseModal} offer={selectedOffer} />
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  )
}

export default InvoiceReviewPage
