import { Button as MantineButton, ScrollArea } from '@mantine/core'
import { Box, Button, Divider, Grid, Link, List, Modal, Stack, Typography } from '@mui/material'
import { IconPhoto } from '@tabler/icons-react'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg'
import { ReactComponent as PdfIcon } from '../../../assets/images/pdf.svg'
import { apiCall } from '../../../components/api/api'
import CopyTextButton from '../../../components/copyButton/copy_button'
import FilePreview from '../../../components/file/file_preview'
import { formatCurrency } from '../../../components/helpers/helper'
import ImageViewerModal from '../../../components/imageViewer/image_viewer'
import InvoiceNumberCellText from '../../../components/invoice_number_cell_text'
import { ServiceDetails, TirePositionsFieldsType } from '../../service_review/models/diagnostic_report'
import { ConsumerRequestOffer } from '../models/invoice_report'
import ApprovalModal from './approval'
import FeedbackForShopModal from './feedback_for_shop'

interface InvoiceReportModalProps {
  open: boolean
  onClose: () => void
  offer: ConsumerRequestOffer | null
}

const InvoiceReportModal: React.FC<InvoiceReportModalProps> = ({ open, onClose, offer }) => {
  const [feedbackForShopModalOpen, setFeedbackForShopModalOpen] = useState(false)
  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  const navigate = useNavigate()
  const [openImageModal, setOpenImageModal] = React.useState(false)
  const handleOpenImageModal = () => setOpenImageModal(true)
  const handleCloseImageModal = () => setOpenImageModal(false)
  if (!offer) return null

  const requestedServicesSubtotal = offer.offer.consumer_request.status_logs
    .filter((log) => log.status === 'REQUESTED')
    .flatMap((log) => log.services)
    .reduce((acc, service) => acc + parseFloat(service.price || '0'), 0)

  const additionalServicesSubtotal = offer.offer.consumer_request.status_logs
    .filter((log) => log.status === 'PENDING_APPROVAL')
    .flatMap((log) => log.services)
    .reduce((acc, service) => acc + parseFloat(service.price || '0'), 0)

  const subtotal = requestedServicesSubtotal + additionalServicesSubtotal

  const supplyFee = parseFloat(offer.offer.supply_fee || offer.offer.new_supply_fee || '0.00')

  const tax = parseFloat(offer.offer.tax || offer.offer.new_tax || '0.00')
  const total = subtotal + supplyFee + tax

  const handleOpenFeedbackForShopModal = () => {
    setFeedbackForShopModalOpen(true)
  }

  const handleCloseFeedbackForShopModal = () => {
    setFeedbackForShopModalOpen(false)
  }

  const handleOpenApprovalModal = () => setApprovalModalOpen(true)
  const handleApprovalCloseModal = () => setApprovalModalOpen(false)

  const handleApprovalConfirmAction = () => {
    const url = `admin/offer-invoice/${offer.consumer_request_offer_id}/approve`
    apiCall(url, 'post')
      .then((response) => {
        handleApprovalCloseModal()
        enqueueSnackbar('Invoice succesfully approved!', { variant: 'success' })
        navigate(0)
      })
      .catch((error) => {
        console.error('Error:', error)
        handleApprovalCloseModal()
        enqueueSnackbar('Failed to approve invoice:', { variant: 'error' })
        navigate(0)
      })
  }

  const handleRejectAction = async (instruction: string) => {
    const requestBody = {
      instruction,
    }

    try {
      await apiCall(
        `admin/offer-invoice/${offer.consumer_request_offer_id}/reject`,
        'post',
        JSON.stringify(requestBody),
      )
      handleCloseFeedbackForShopModal()
      enqueueSnackbar('Invoice succesfully sent back to the shop!', { variant: 'success' })
      navigate(0)
    } catch (error) {
      console.error('Error:', error)
      handleApprovalCloseModal()
      enqueueSnackbar('Failed to sent back to the shop:', { variant: 'error' })
      navigate(0)
    }
  }

  const tirePositionsToMeasurementMapping: Record<string, keyof TirePositionsFieldsType> = {
    LR: 'LR_MEASUREMENT',
    RR: 'RR_MEASUREMENT',
    LF: 'LF_MEASUREMENT',
    RF: 'RF_MEASUREMENT',
    LR_INSIDE: 'LR_INSIDE_MEASUREMENT',
    RR_INSIDE: 'RR_INSIDE_MEASUREMENT',
  }

  const getPartsInfo = (item: ServiceDetails) => {
    if (item.parts.length === 0) {
      return (
        <Typography style={{ fontSize: '12px' }} mt="3px">
          <b>Parts:</b> N/A
        </Typography>
      )
    }
    return (
      <>
        <Typography style={{ fontSize: '12px' }} mt="3px">
          <b>Parts:</b>
        </Typography>
        <Box display="flex" flexDirection="column" gap={'1px'} mt="2px">
          {item.parts.map((partsItem) => (
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={'1px'} mt="1px">
              <Typography style={{ fontSize: '12px', maxWidth: '250px' }}>
                {partsItem.number} - {partsItem.name}
              </Typography>
              <Typography style={{ fontSize: '12px' }}>
                Parts cost:{' '}
                <b>{formatCurrency(Math.round(partsItem.price_per_unit * partsItem.quantity * 100) / 100)}</b>
              </Typography>
            </Box>
          ))}
        </Box>
      </>
    )
  }

  const displayImages = (item: ServiceDetails) => {
    if (item.images?.length > 0) {
      return (
        <>
          <ImageViewerModal open={openImageModal} onClose={handleCloseImageModal} imageUrls={item.images} />
          <MantineButton
            onClick={handleOpenImageModal}
            leftSection={<IconPhoto size={14} />}
            variant="outline"
            color="orange"
            size="xs">
            View images
          </MantineButton>
        </>
      )
    }

    return <div></div> // return empty div so flex container enforces space-between
  }

  const getLaborHourInfo = (item: ServiceDetails) => {
    const laborHours = item.labor_hours

    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap={'2px'} mt="2px">
        <Typography style={{ fontSize: '12px' }}>
          Labor hours: <b>{laborHours.toFixed(5)}</b>
        </Typography>
        <Typography style={{ fontSize: '12px' }}>
          Labor cost: <b>{formatCurrency(Math.round(offer.offer.labor_rate * laborHours * 100) / 100)}</b>
        </Typography>
      </Box>
    )
  }

  const getMeasurementString = (item: ServiceDetails) => {
    const additionalData = item.additional_data

    if (additionalData?.LF_PAD || additionalData?.RF_PAD || additionalData?.LR_PAD || additionalData?.RR_PAD) {
      return (
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap={'6px'}>
            <Typography style={{ fontSize: '12px' }}>
              <b>LF Pad: </b> {additionalData?.LF_PAD ?? 'N/A'}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>
              <b>RF Pad: </b> {additionalData?.RF_PAD ?? 'N/A'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap={'6px'}>
            <Typography style={{ fontSize: '12px' }}>
              <b>LR Pad: </b> {additionalData?.LR_PAD ?? 'N/A'}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>
              <b>RR Pad: </b> {additionalData?.RR_PAD ?? 'N/A'}
            </Typography>
          </Box>
        </>
      )
    }

    // handle battery replacement
    if (additionalData?.COLD_CRANKING_AMPS || additionalData?.FACTORY_COLD_CRANKING_AMPS) {
      return (
        <>
          <Typography style={{ fontSize: '12px' }}>
            <b>Cold cranking amps: </b> {additionalData?.COLD_CRANKING_AMPS ?? 'N/A'}
          </Typography>
          <Typography style={{ fontSize: '12px' }}>
            <b>Factory cold cranking amps: </b> {additionalData?.FACTORY_COLD_CRANKING_AMPS ?? 'N/A'}
          </Typography>
        </>
      )
    }

    // handle tires
    if (additionalData?.TIRE_POSITIONS) {
      let tiresToBeReplacedStrings = []
      if (additionalData && additionalData.TIRE_POSITIONS?.length > 0) {
        for (const tirePosition of additionalData.TIRE_POSITIONS) {
          let tireString = `${tirePosition}`
          const measurement = additionalData[tirePositionsToMeasurementMapping[tirePosition]]
          if (measurement) {
            tireString += ` (${measurement})`
          }
          tiresToBeReplacedStrings.push(tireString)
        }
        return (
          <>
            <Typography style={{ fontSize: '12px', fontWeight: 600 }}>Tires to be replaced:</Typography>
            <Typography style={{ fontSize: '12px' }}>{tiresToBeReplacedStrings.join(', ')}</Typography>
            <Typography style={{ fontSize: '12px' }}>
              <b>Brand: </b> {additionalData.TIRE_BRAND ?? 'N/A'}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>
              <b>Model: </b> {additionalData.TIRE_MODEL ?? 'N/A'}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>
              <b>Warranty: </b> {additionalData.WARRANTY?.toLocaleString('en-US') ?? 'N/A'}
            </Typography>
          </>
        )
      }
    }
    return null
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div>
        <ScrollArea w={'100vw'} h="70%" hiddenFrom="xs" mt="10vh" mb="10vh">
          <Box
            sx={{
              display: 'flex',
              minWidth: '1100px',
              width: '100%',
              maxHeight: '80vh',
              overflowY: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              flexDirection: 'column',
            }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
              Invoice
            </Typography>
            <Divider sx={{ mb: 2, width: '100%' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ borderRight: '1px solid #EAECF0' }}>
                <Box display="flex" flexDirection="row" alignItems="center" mb={2} gap={1}>
                  <PdfIcon />
                  <Typography>{offer.name}</Typography>
                  <Link
                    href={offer.url}
                    download
                    target="_blank"
                    sx={{
                      marginLeft: 'auto',
                      fontWeight: 'bold',
                      color: '#DB5D08',
                      textDecoration: 'none',
                      mr: 1,
                      whiteSpace: 'nowrap',
                    }}>
                    <DownloadIcon /> Download
                  </Link>
                </Box>
                <Divider />

                {offer?.offer.consumer_request.files?.length > 0 ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: '600', color: '#1D2939', mb: 1 }}>
                      Uploaded files by customer
                    </Typography>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={1}>
                      <FilePreview files={offer?.offer.consumer_request.files || []} />
                    </Box>
                    <Divider />
                  </Box>
                ) : (
                  ''
                )}

                <Box sx={{ mb: 2, mt: 1 }}>
                  {offer?.offer.consumer_request.additional_information ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontWeight: '600', color: '#1D2939', display: 'flex' }}>
                        Customer comments
                        {offer?.offer.consumer_request.additional_information && (
                          <CopyTextButton value={offer?.offer.consumer_request.additional_information} />
                        )}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                        {offer?.offer.consumer_request.additional_information}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  )}
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Service Center</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>{offer.offer.provider_branch.name}</Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Request ID</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                    {offer.offer.consumer_request.slug}
                    {offer.offer.consumer_request.slug && <CopyTextButton value={offer.offer.consumer_request.slug} />}
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Invoice Number</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                    <InvoiceNumberCellText invoiceNumber={offer.offer.invoice_number} />
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    {`${offer.offer.consumer_request.vehicle?.year} ${offer.offer.consumer_request.vehicle?.make} ${offer.offer.consumer_request.vehicle?.model}`}
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Mileage</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    {offer.offer.in_mileage.toLocaleString()} miles
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Engine size</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    {offer.offer.consumer_request.vehicle.engine_size}
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle ID</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                    {offer.offer.consumer_request.vehicle?.name}
                    {offer.offer.consumer_request.vehicle?.name && (
                      <CopyTextButton value={offer.offer.consumer_request.vehicle?.name} />
                    )}
                  </Typography>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>VIN</Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2, display: 'flex' }}>
                    {offer.offer.consumer_request.vehicle?.vin}
                    {offer.offer.consumer_request.vehicle?.vin && (
                      <CopyTextButton value={offer.offer.consumer_request.vehicle?.vin} />
                    )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                {offer.offer.consumer_request.status_logs.filter((log) => log.status === 'REQUESTED').length > 0 && (
                  <>
                    <Typography sx={{ fontWeight: '600', mb: 1 }}>Requested services</Typography>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                      }}>
                      {offer.offer?.consumer_request.status_logs
                        .filter((log) => log.status === 'REQUESTED')
                        .flatMap((log) => log.services)
                        .map((item) => (
                          <Box
                            key={item.service.id}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            sx={{
                              mb: 1,
                              p: 1,
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                            }}>
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#393A3D',
                                }}>
                                {item.service.parent?.parent_id ? item.service.parent.name : item.service.name}
                              </Typography>
                              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                                {item.service.parent?.parent_id && item.service.name}
                              </Typography>
                              {item.service.name && getMeasurementString(item) && (
                                <>
                                  <Box
                                    sx={{
                                      mt: '1px',
                                      mb: '1px',
                                      p: 1,
                                      borderRadius: '8px',
                                      border: '1px solid #EAECF0',
                                      fontSize: '12px',
                                      width: '100%',
                                      mr: '4px',
                                    }}>
                                    {getMeasurementString(item)}
                                  </Box>
                                </>
                              )}
                            </Stack>
                            {getLaborHourInfo(item)}
                            {getPartsInfo(item)}

                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                              mt="15px">
                              {displayImages(item)}
                              <Typography style={{ fontSize: '14px', color: '#ff6600' }}>
                                Service total: <b>{formatCurrency(item.price)}</b>
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </List>
                  </>
                )}
                {offer.offer.consumer_request.status_logs.filter((log) => log.status === 'PENDING_APPROVAL').length >
                  0 && (
                  <>
                    <Typography sx={{ fontWeight: '600', mb: 1, mt: 1 }}>Recommended services</Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}>
                      {offer.offer?.consumer_request.status_logs
                        .filter((log) => log.status === 'PENDING_APPROVAL')
                        .flatMap((log) => log.services)
                        .map((item) => (
                          <Box
                            key={item.service.id}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            sx={{
                              mb: 1,
                              p: 1,
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                            }}>
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  color: '#393A3D',
                                }}>
                                {item.service.parent?.parent_id ? item.service.parent?.name : item.service.name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {item.service.parent?.parent_id && item.service.name}
                              </Typography>
                              {item.service.name && getMeasurementString(item) && (
                                <>
                                  <Box
                                    sx={{
                                      mt: '1px',
                                      mb: '1px',
                                      p: 1,
                                      borderRadius: '8px',
                                      border: '1px solid #EAECF0',
                                      fontSize: '12px',
                                      width: '100%',
                                      mr: '4px',
                                    }}>
                                    {getMeasurementString(item)}
                                  </Box>
                                </>
                              )}
                            </Stack>
                            {getLaborHourInfo(item)}
                            {getPartsInfo(item)}
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                              mt="15px">
                              {displayImages(item)}
                              <Typography style={{ fontSize: '14px', color: '#ff6600' }}>
                                Service total: <b>{formatCurrency(item.price)}</b>
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </List>
                  </>
                )}
                <Divider sx={{ my: 1 }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Requested service subtotal:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '600',
                    }}>
                    {formatCurrency(requestedServicesSubtotal)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Recommended service subtotal:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '600',
                    }}>
                    {formatCurrency(additionalServicesSubtotal)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Subtotal:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}>
                    {formatCurrency(subtotal)}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '4px',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Shop supply fees
                  </Typography>
                  <Typography>{formatCurrency(supplyFee)}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '4px',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Tax
                  </Typography>
                  <Typography>{formatCurrency(tax)}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 1,
                    p: 1,
                    borderRadius: '8px',
                    border: '1px solid #EAECF0',
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}>
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}>
                    {formatCurrency(total)}
                  </Typography>
                </Box>
                {offer.offer.labor_rate && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingLeft: 1,
                      paddingRight: 1,
                      marginBottom: 2,
                    }}>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        color: '#475467',
                        width: '100%',
                      }}>
                      Labor rate: {formatCurrency(offer.offer.labor_rate)}/hr
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                color="warning"
                onClick={handleOpenFeedbackForShopModal}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  textTransform: 'none',
                  mr: 2,
                  fontWeight: 600,
                  padding: '8px 16px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 165, 0, 0.2)',
                  },
                }}>
                Send back for shop adjustments
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={handleOpenApprovalModal}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  fontWeight: 600,
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 165, 0, 0.5)',
                  },
                }}>
                Approve and send to customer
              </Button>
            </Box>
            <FeedbackForShopModal
              open={feedbackForShopModalOpen}
              onClose={handleCloseFeedbackForShopModal}
              onConfirm={handleRejectAction}
            />
            <ApprovalModal
              open={approvalModalOpen}
              onClose={handleApprovalCloseModal}
              onConfirm={handleApprovalConfirmAction}
            />
          </Box>
        </ScrollArea>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '1100px',
            width: 'auto',
            maxWidth: {
              xs: 'calc(100% - 20px)',
              sm: '1100px',
              md: '1400px',
            },
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
          }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
            Invoice
          </Typography>
          <Divider sx={{ mb: 2, width: '100%' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ borderRight: '1px solid #EAECF0' }}>
              <Box display="flex" flexDirection="row" alignItems="center" mb={2} gap={1}>
                <PdfIcon />
                <Typography>{offer.name}</Typography>
                <Link
                  href={offer.url}
                  download
                  target="_blank"
                  sx={{
                    marginLeft: 'auto',
                    fontWeight: 'bold',
                    color: '#DB5D08',
                    textDecoration: 'none',
                    mr: 1,
                    whiteSpace: 'nowrap',
                  }}>
                  <DownloadIcon /> Download
                </Link>
              </Box>
              <Divider />

              {offer?.offer.consumer_request.files?.length > 0 ? (
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939', mb: 1 }}>
                    Uploaded files by customer
                  </Typography>
                  <Box display="flex" justifyContent="flex-start" alignItems="center" mb={1}>
                    <FilePreview files={offer?.offer.consumer_request.files || []} />
                  </Box>
                  <Divider />
                </Box>
              ) : (
                ''
              )}

              <Box sx={{ mb: 2, mt: 1 }}>
                {offer?.offer.consumer_request.additional_information ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: '600', color: '#1D2939', display: 'flex' }}>
                      Customer comments
                      {offer?.offer.consumer_request.additional_information && (
                        <CopyTextButton value={offer?.offer.consumer_request.additional_information} />
                      )}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                      {offer?.offer.consumer_request.additional_information}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Service Center</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>{offer.offer.provider_branch.name}</Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Request ID</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                  {offer.offer.consumer_request.slug}
                  {offer.offer.consumer_request.slug && <CopyTextButton value={offer.offer.consumer_request.slug} />}
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Invoice Number</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                  <InvoiceNumberCellText invoiceNumber={offer.offer.invoice_number} />
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  {`${offer.offer.consumer_request.vehicle?.year} ${offer.offer.consumer_request.vehicle?.make} ${offer.offer.consumer_request.vehicle?.model}`}
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Mileage</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  {offer.offer.in_mileage.toLocaleString()} miles
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Engine size</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  {offer.offer.consumer_request.vehicle.engine_size}
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle ID</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 1, display: 'flex' }}>
                  {offer.offer.consumer_request.vehicle?.name}
                  {offer.offer.consumer_request.vehicle?.name && (
                    <CopyTextButton value={offer.offer.consumer_request.vehicle?.name} />
                  )}
                </Typography>
                <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>VIN</Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2, display: 'flex' }}>
                  {offer.offer.consumer_request.vehicle?.vin}
                  {offer.offer.consumer_request.vehicle?.vin && (
                    <CopyTextButton value={offer.offer.consumer_request.vehicle?.vin} />
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              {offer.offer.consumer_request.status_logs.filter((log) => log.status === 'REQUESTED').length > 0 && (
                <>
                  <Typography sx={{ fontWeight: '600', mb: 1 }}>Requested services</Typography>
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      paddingTop: '1px',
                      paddingBottom: '1px',
                    }}>
                    {offer.offer?.consumer_request.status_logs
                      .filter((log) => log.status === 'REQUESTED')
                      .flatMap((log) => log.services)
                      .map((item) => (
                        <Box
                          key={item.service.id}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          sx={{
                            mb: 1,
                            p: 1,
                            borderRadius: '8px',
                            border: '1px solid #EAECF0',
                          }}>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#393A3D',
                              }}>
                              {item.service.parent?.parent_id ? item.service.parent.name : item.service.name}
                            </Typography>
                            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                              {item.service.parent?.parent_id && item.service.name}
                            </Typography>
                            {item.service.name && getMeasurementString(item) && (
                              <>
                                <Box
                                  sx={{
                                    mt: '1px',
                                    mb: '1px',
                                    p: 1,
                                    borderRadius: '8px',
                                    border: '1px solid #EAECF0',
                                    fontSize: '12px',
                                    width: '100%',
                                    mr: '4px',
                                  }}>
                                  {getMeasurementString(item)}
                                </Box>
                              </>
                            )}
                          </Stack>
                          {getLaborHourInfo(item)}
                          {getPartsInfo(item)}

                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt="15px">
                            {displayImages(item)}
                            <Typography style={{ fontSize: '14px', color: '#ff6600' }}>
                              Service total: <b>{formatCurrency(item.price)}</b>
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </List>
                </>
              )}
              {offer.offer.consumer_request.status_logs.filter((log) => log.status === 'PENDING_APPROVAL').length >
                0 && (
                <>
                  <Typography sx={{ fontWeight: '600', mb: 1, mt: 1 }}>Recommended services</Typography>
                  <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}>
                    {offer.offer?.consumer_request.status_logs
                      .filter((log) => log.status === 'PENDING_APPROVAL')
                      .flatMap((log) => log.services)
                      .map((item) => (
                        <Box
                          key={item.service.id}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          sx={{
                            mb: 1,
                            p: 1,
                            borderRadius: '8px',
                            border: '1px solid #EAECF0',
                          }}>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#393A3D',
                              }}>
                              {item.service.parent?.parent_id ? item.service.parent?.name : item.service.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {item.service.parent?.parent_id && item.service.name}
                            </Typography>
                            {item.service.name && getMeasurementString(item) && (
                              <>
                                <Box
                                  sx={{
                                    mt: '1px',
                                    mb: '1px',
                                    p: 1,
                                    borderRadius: '8px',
                                    border: '1px solid #EAECF0',
                                    fontSize: '12px',
                                    width: '100%',
                                    mr: '4px',
                                  }}>
                                  {getMeasurementString(item)}
                                </Box>
                              </>
                            )}
                          </Stack>
                          {getLaborHourInfo(item)}
                          {getPartsInfo(item)}
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt="15px">
                            {displayImages(item)}
                            <Typography style={{ fontSize: '14px', color: '#ff6600' }}>
                              Service total: <b>{formatCurrency(item.price)}</b>
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </List>
                </>
              )}
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Requested service subtotal:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                  }}>
                  {formatCurrency(requestedServicesSubtotal)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Recommended service subtotal:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                  }}>
                  {formatCurrency(additionalServicesSubtotal)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Subtotal:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}>
                  {formatCurrency(subtotal)}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: '4px',
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Shop supply fees
                </Typography>
                <Typography>{formatCurrency(supplyFee)}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: '4px',
                  paddingRight: 1,
                  paddingLeft: 1,
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Tax
                </Typography>
                <Typography>{formatCurrency(tax)}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 1,
                  p: 1,
                  borderRadius: '8px',
                  border: '1px solid #EAECF0',
                }}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}>
                  Total
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}>
                  {formatCurrency(total)}
                </Typography>
              </Box>
              {offer.offer.labor_rate && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 1,
                    paddingRight: 1,
                    marginBottom: 2,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      color: '#475467',
                      width: '100%',
                    }}>
                    Labor rate: {formatCurrency(offer.offer.labor_rate)}/hr
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              color="warning"
              onClick={handleOpenFeedbackForShopModal}
              sx={{
                width: '100%',
                borderRadius: 2,
                textTransform: 'none',
                mr: 2,
                fontWeight: 600,
                padding: '8px 16px',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'rgba(255, 165, 0, 0.2)',
                },
              }}>
              Send back for shop adjustments
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleOpenApprovalModal}
              sx={{
                width: '100%',
                borderRadius: 2,
                fontWeight: 600,
                textTransform: 'none',
                padding: '8px 16px',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: 'rgba(255, 165, 0, 0.5)',
                },
              }}>
              Approve and send to customer
            </Button>
          </Box>
          <FeedbackForShopModal
            open={feedbackForShopModalOpen}
            onClose={handleCloseFeedbackForShopModal}
            onConfirm={handleRejectAction}
          />
          <ApprovalModal
            open={approvalModalOpen}
            onClose={handleApprovalCloseModal}
            onConfirm={handleApprovalConfirmAction}
          />
        </Box>
      </div>
    </Modal>
  )
}

export default InvoiceReportModal
