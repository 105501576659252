import { ActionIcon, Button, Card, Center, Group, Modal, Select, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import {
  IconAlertTriangle,
  IconFileSmile,
  IconPhoto,
  IconPlus,
  IconUpload,
  IconX,
  IconXboxX,
} from '@tabler/icons-react'
import { useState } from 'react'

import StepContainer from '../components/StepContainer'
import {
  isRequestOfferFileType,
  RequestOfferFileType,
  requestOfferFileTypes,
  RequestOfferFileUpload,
} from '../formTypes'

const NoFilesWarningModal = ({
  opened,
  onClose,
  onConfirm,
}: {
  opened: boolean
  onClose: () => void
  onConfirm: () => void
}) => (
  <Modal
    opened={opened}
    onClose={onClose}
    title={
      <Group gap={4}>
        <ThemeIcon size="md" variant="transparent">
          <IconAlertTriangle />
        </ThemeIcon>
        <Title order={3}>No Files Added</Title>
      </Group>
    }
    size="lg">
    <Stack p="lg">
      <Text>
        You haven't added any files to this service record. Files like invoices and work orders are important for record
        keeping.
      </Text>
      <Text>Are you sure you want to continue without adding any files?</Text>

      <Group justify="flex-end" mt="md">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Continue Without Files</Button>
      </Group>
    </Stack>
  </Modal>
)

const FileUpload = ({
  isLast,
  onFileChange,
  onTypeChange,
  onRemove,
  chosenTypes,
  file,
  type,
}: {
  isLast: boolean
  onFileChange: (file: File) => void
  onTypeChange: (type: RequestOfferFileType) => void
  onRemove: () => void
  chosenTypes: Set<RequestOfferFileType>
  file?: File
  type?: RequestOfferFileType
}) => {
  const FILE_MAX_MB = 30

  const data = requestOfferFileTypes.map((ft) => ({
    value: ft,
    label: ft,
    disabled: chosenTypes.has(ft),
  }))

  return (
    <Card withBorder w="100%" shadow="sm" p="lg">
      <Stack flex={'1 0 auto'} gap="md" w="700">
        <Select
          size="xl"
          placeholder="Select file type"
          label="File Type"
          data={data}
          value={type}
          onChange={(ft) => ft && isRequestOfferFileType(ft) && onTypeChange(ft)}
        />
        <Group>
          <Dropzone
            onReject={(files) => console.error('rejected files', files)}
            onDrop={(files) => onFileChange(files[0])}
            multiple={false}
            accept={(IMAGE_MIME_TYPE as string[]).concat(PDF_MIME_TYPE)}
            maxSize={FILE_MAX_MB * 1024 ** 2}
            flex={1}>
            <Group justify="center" gap="xl" mih={75} style={{ pointerEvents: 'none' }}>
              <Dropzone.Accept>
                {file ? (
                  <IconFileSmile size={52} color="var(--mantine-color-blue-6)" stroke={1.5} />
                ) : (
                  <IconUpload size={52} color="var(--mantine-color-blue-6)" stroke={1.5} />
                )}
              </Dropzone.Accept>
              <Dropzone.Idle>
                {file ? (
                  <IconFileSmile size={52} color="var(--mantine-color-dimmed)" stroke={1.5} />
                ) : (
                  <IconPhoto size={52} color="var(--mantine-color-dimmed)" stroke={1.5} />
                )}
              </Dropzone.Idle>
              <Dropzone.Reject>
                <IconX size={52} color="var(--mantine-color-red-6)" stroke={1.5} />
              </Dropzone.Reject>

              <div>
                <Text size="xl" inline>
                  {file ? `Current file: ${file.name}` : 'Drag images here or click to select files'}
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  A {FILE_MAX_MB}mb or smaller file
                </Text>
                <Dropzone.Reject>
                  <Text size="sm" c="red" mt={7}>
                    Invalid file: file must be an image or pdf, and must not exceed {FILE_MAX_MB}mb
                  </Text>
                </Dropzone.Reject>
              </div>
            </Group>
          </Dropzone>
          <ActionIcon disabled={isLast} onClick={onRemove}>
            <IconXboxX size={24} />
          </ActionIcon>
        </Group>
      </Stack>
    </Card>
  )
}

const OfferFiles = ({
  files,
  onBack,
  onNext,
}: {
  files?: RequestOfferFileUpload[]
  onBack: () => void
  onNext: ({ fileObjects }: { fileObjects: RequestOfferFileUpload[] }) => void
}) => {
  const [uploads, setUploads] = useState<Partial<RequestOfferFileUpload>[]>(
    files && files.length > 0 ? files : [{ file: undefined, type: undefined }],
  )
  const [hasConfirmedNoFiles, setHasConfirmedNoFiles] = useState(false)
  const [showNoFilesModal, { open: openNoFilesModal, close: closeNoFilesModal }] = useDisclosure(false)

  // Old ts version isn't smart enough to realize that the undefined values are filtered out
  const chosenTypes = new Set(uploads.filter((ul) => !!ul.type).map((ul) => ul.type)) as Set<RequestOfferFileType>

  const addUpload = () => {
    setUploads([...uploads, { file: undefined, type: undefined }])
  }

  const updateUpload = (index: number, updatedUpload: Partial<RequestOfferFileUpload>) => {
    const newUploads = [...uploads]
    newUploads[index] = updatedUpload
    setUploads(newUploads)
  }

  const handleNext = (confirmed: boolean) => {
    confirmed = confirmed || hasConfirmedNoFiles
    const fileObjects = uploads.filter((upload) => upload.file && upload.type) as RequestOfferFileUpload[]

    if (fileObjects.length === 0 && !confirmed) {
      openNoFilesModal()
      return
    }
    if (fileObjects.length === 0) {
      onNext({ fileObjects: [] })
    } else {
      onNext({ fileObjects: fileObjects })
    }
  }

  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleNext(false)}
      nextDisabled={uploads.some(
        (upload) => (!upload.file || !upload.type) && !(upload.file === undefined && upload.type === undefined),
      )}
      title="Upload files">
      <Stack flex={1}>
        {uploads.map((upload, index) => (
          <FileUpload
            key={`${upload.type}-${upload.file?.name}`}
            isLast={index === 0 && uploads.length === 1}
            file={upload.file}
            type={upload.type}
            chosenTypes={chosenTypes}
            onRemove={() => setUploads(uploads.filter((_, i) => i !== index))}
            onFileChange={(file) => updateUpload(index, { ...upload, file })}
            onTypeChange={(type: RequestOfferFileType) => updateUpload(index, { ...upload, type })}
          />
        ))}
        <Center>
          <ActionIcon onClick={addUpload}>
            <IconPlus size={24} />
          </ActionIcon>
        </Center>
      </Stack>

      <NoFilesWarningModal
        opened={showNoFilesModal}
        onClose={closeNoFilesModal}
        onConfirm={() => {
          setHasConfirmedNoFiles(true)
          closeNoFilesModal()
          handleNext(true)
        }}
      />
    </StepContainer>
  )
}

export default OfferFiles
