import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import StepContainer from '../components/StepContainer'
import { ManualRequestDateSchema } from '../formTypes'

const dateSelectSchema = z.object({
  date: ManualRequestDateSchema,
})

type DateSelectSchema = z.infer<typeof dateSelectSchema>

const DateSelect = ({ onBack, onNext, date }: { date?: Date; onBack?: () => void; onNext: (date: Date) => void }) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<DateSelectSchema>({
    defaultValues: { date: date || new Date() },
    resolver: zodResolver(dateSelectSchema),
  })

  const selectedDate = watch('date')

  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleSubmit((data) => onNext(data.date))()}
      nextDisabled={!selectedDate}
      errors={errors}
      title="Select a service date">
      <Stack align="center">
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, value } }) => (
            <DatePicker value={value} onChange={onChange} maxDate={new Date()} size="xl" firstDayOfWeek={0} />
          )}
        />
      </Stack>
    </StepContainer>
  )
}

export default DateSelect
