import { zodResolver } from '@hookform/resolvers/zod'
import {
  ActionIcon,
  Box,
  Card,
  Center,
  Combobox,
  Group,
  InputWrapper,
  Loader,
  NumberFormatter,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Title,
  useCombobox,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { apiCall } from '../../../components/api/api'
import StepContainer from '../components/StepContainer'
import { ConsumerRequestOfferService, ConsumerRequestOfferServiceSchema } from '../formTypes'
import { BaseServiceSchema } from '../models'

// Schema for the API response
const ServiceListSchema = z.object({
  items: z.array(BaseServiceSchema),
  count: z.number(),
})

// Schema for form validation
const offerServicesSchema = z.object({
  services: z.array(ConsumerRequestOfferServiceSchema).min(1, 'Please select at least one service'),
})

type OfferServicesSchema = z.infer<typeof offerServicesSchema>
type Service = z.infer<typeof BaseServiceSchema>

interface OfferServiceBlockProps {
  service_id: string
  service_name: string
  price: number
  onPriceChange: (price: number | string) => void
  onRemove: () => void
}

export const OfferServiceBlock: React.FC<OfferServiceBlockProps> = ({
  service_id,
  service_name,
  price,
  onPriceChange,
  onRemove,
}) => (
  <Box
    p="lg"
    bg="#F9FAFB"
    style={{
      borderRadius: 8,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
      border: '1px solid #E5E7EB',
    }}>
    <Stack gap="lg">
      {service_id && (
        <Group justify="space-between">
          <Title order={4}>{service_name}</Title>
          <ActionIcon onClick={onRemove} variant="subtle" color="gray" style={{ gap: 8 }} w="auto">
            <Group gap={4} flex={1}>
              <IconX size={16} />
              <Text size="sm" fw={500} c="dimmed">
                Remove service
              </Text>
            </Group>
          </ActionIcon>
        </Group>
      )}

      <Group justify="flex-end" align="center">
        <NumberInput
          label="Service cost"
          value={price}
          onChange={onPriceChange}
          min={0}
          decimalScale={2}
          prefix="$"
          hideControls
          style={{ maxWidth: 200 }}
        />
      </Group>
    </Stack>
  </Box>
)

const ManualRequestServices = ({
  onBack,
  onNext,
  services,
}: {
  services?: ConsumerRequestOfferService[]
  onBack: () => void
  onNext: (services: ConsumerRequestOfferService[]) => void
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearch] = useDebouncedValue(searchTerm, 300)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<OfferServicesSchema>({
    defaultValues: {
      services: services || [],
    },
    resolver: zodResolver(offerServicesSchema),
  })

  const {
    data,
    isLoading: servicesLoading,
    error,
  } = useQuery({
    queryKey: ['services', debouncedSearch],
    queryFn: async () => {
      // NOTE: api doesn't currently support multiple levels, so hack them together in client
      let data = await apiCall(`service?search=${debouncedSearch}&level=2&limit=12`, 'get')
      const level2out = ServiceListSchema.safeParse(data)
      if (level2out.error) {
        throw new Error(JSON.stringify(level2out.error))
      }
      data = await apiCall(`service?search=${debouncedSearch}&level=3&limit=5`, 'get')
      const level3out = ServiceListSchema.safeParse(data)
      if (level3out.error) {
        throw new Error(JSON.stringify(level3out.error))
      }
      return {
        items: [...level2out.data.items, ...level3out.data.items],
        count: level2out.data.count + level3out.data.count,
      }
    },
  })

  const allServices: Service[] = data?.items || []

  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleSubmit((data) => onNext(data.services))()}
      nextDisabled={!isValid}
      errors={error}
      title="Select services">
      <Controller
        control={control}
        name="services"
        render={({ field: { onChange, value: values }, fieldState: { error } }) => (
          <Stack w={700}>
            <Combobox size="xl" store={combobox}>
              <Combobox.Target>
                <TextInput
                  placeholder="Search for services"
                  leftSection={<IconSearch />}
                  value={searchTerm}
                  onChange={(event) => {
                    combobox.openDropdown()
                    setSearchTerm(event.currentTarget.value)
                  }}
                  onClick={() => combobox.openDropdown()}
                />
              </Combobox.Target>
              <Combobox.Dropdown>
                <Combobox.Options>
                  {servicesLoading && (
                    <Combobox.Empty>
                      <Loader />
                    </Combobox.Empty>
                  )}
                  {allServices.length === 0 ? (
                    <Combobox.Empty>
                      <Text truncate="end">
                        {searchTerm.length === 0 ? 'Enter the search term' : `No services match ${searchTerm}`}
                      </Text>
                    </Combobox.Empty>
                  ) : (
                    allServices.map((service) => (
                      <Combobox.Option
                        key={service.id}
                        value={service.name}
                        disabled={values.some((opt) => opt.service_id === service.id)}
                        onClick={() => {
                          if (!values.some((opt) => opt.service_id === service.id)) {
                            onChange([
                              ...values,
                              {
                                service_id: service.id,
                                service_name: service.name,
                                price: 0,
                              },
                            ])
                          }
                          combobox.closeDropdown()
                        }}>
                        {service.name}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            {servicesLoading && (
              <Center>
                <Loader />
              </Center>
            )}
            {values.length === 0 && (
              <InputWrapper error={error?.message}>
                <Card h={200} radius="md" bg="transparent">
                  <Center h={'100%'}>
                    <Text c={error ? 'red' : 'gray.5'}>Select a service to get started</Text>
                  </Center>
                </Card>
              </InputWrapper>
            )}
            {values.map((offerService, index) => (
              <OfferServiceBlock
                key={offerService.service_id}
                service_name={offerService.service_name}
                service_id={offerService.service_id}
                price={offerService.price}
                onRemove={() => {
                  onChange(values.filter((s) => s.service_id !== offerService.service_id))
                }}
                onPriceChange={(price) => {
                  price = typeof price === 'string' ? Number(price) : price
                  onChange([...values.slice(0, index), { ...values[index], price }, ...values.slice(index + 1)])
                }}
              />
            ))}

            <Box
              display="flex"
              mt="12px"
              style={{
                justifyContent: 'flex-end',
                width: '100%',
              }}>
              <Text fw={600} fz={16} mr={16} c="#111827">
                Service subtotal:
              </Text>
              <Text fw={600} fz={16} c="#111827">
                <NumberFormatter
                  prefix="$"
                  decimalScale={2}
                  thousandSeparator
                  fixedDecimalScale
                  value={values.reduce((acc, curr) => acc + curr.price, 0)}
                />
              </Text>
            </Box>
          </Stack>
        )}
      />
    </StepContainer>
  )
}

export default ManualRequestServices
