import { Avatar, Box, Group, Text, UnstyledButton } from '@mantine/core'
import { IconLoader2 } from '@tabler/icons-react'
import React from 'react'

export function UserButton({
  userName,
  userEmail,
  onLogout,
}: {
  userName: string
  userEmail: string
  onLogout: () => void
}) {
  return (
    <UnstyledButton
      onClick={onLogout}
      style={{
        display: 'block',
        width: '100%',
        paddingTop: '8px',
        paddingLeft: '8px',
        paddingRight: '1px',
        paddingBottom: '8px',
        color: '#ffffff',
      }}>
      {userEmail ? (
        <Group gap={10}>
          <Avatar color="white" radius="xl" name={userName} autoContrast={true} />

          <div style={{ flex: 1 }}>
            <Box w={150}>
              <Text size="sm" fw={500} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {userName}
              </Text>
            </Box>

            <Box w={150}>
              <Text
                size="xs"
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#fefefe' }}>
                {userEmail}
              </Text>
            </Box>
          </div>
        </Group>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 38 }}>
          <IconLoader2 />
        </div>
      )}
    </UnstyledButton>
  )
}
