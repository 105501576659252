import { IconBallpen, IconFileDescription, IconInvoice, IconTool, IconUpload } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

import { apiCall } from '../api/api'
import { NavbarNested } from '../Menu/NavbarNested'

interface NewSideBarProps {
  onLinkClick?: () => void
}

export const NewSideBar = ({ onLinkClick }: NewSideBarProps) => {
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiCall('admin/auth/is-auth', 'get')
        setUser(response)
      } catch (error) {
        console.error('Failed to fetch username:', error)
      }
    }

    fetchUser()
  }, [])

  let name = user?.name
  let email = user?.email

  return (
    <NavbarNested
      userName={name}
      userEmail={email}
      onLinkClick={onLinkClick}
      items={[
        {
          label: 'Requests',
          to: '/requests',
          icon: IconUpload,
        },
        {
          label: 'Service Review',
          icon: IconFileDescription,
          to: '/service-review',
        },
        { label: 'Invoice Review', to: '/invoice-review', icon: IconInvoice },
        {
          label: 'Service History Upload',
          to: '/service-history-upload',
          icon: IconBallpen,
        },
        {
          label: 'Tools',
          icon: IconTool,
          initiallyOpened: true,
          links: [
            { label: 'Extend request expiry', to: '/tools/extend-request-expiry' },
            { label: 'Create file link', to: '/tools/create-file-link' },
          ],
        },
      ]}
    />
  )
}
