import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Group, ScrollArea, Stack, Table, TextInput } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { IconCarFilled, IconSearch } from '@tabler/icons-react'
import { DataTable, DataTableColumn } from 'mantine-datatable'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { SelectedVehicleCard } from '../components/SelectedVehicleCard'
import StepContainer from '../components/StepContainer'
import { ManualRequestVehicleIdSchema } from '../formTypes'
import { useListVehicles } from '../hooks/useVehicle'

const vehicleSelectSchema = z.object({
  vehicleId: ManualRequestVehicleIdSchema,
})

type VehicleSelectSchema = z.infer<typeof vehicleSelectSchema>

type VehicleColumns = 'name' | 'ymm' | 'vin' | 'consumer'

type VehicleListProps = {
  searchTerm: string | null
  columns: VehicleColumns[]
  recordsPerPage: number
  onSelectedVehicleIdChange: (vehicleId: string | null) => void
  selectedVehicleId: string | null
}

type VehicleRow = {
  name: string
  id: string
  ymm: string
  vin: string
}

export const VehicleTable = ({
  searchTerm,
  recordsPerPage: initialRecordsPerPage = 20,
  columns,
  onSelectedVehicleIdChange,
  selectedVehicleId,
}: VehicleListProps) => {
  const [pageSize, setPageSize] = useState(initialRecordsPerPage)
  const [page, setPage] = useState(1)
  const { data, isLoading: listVehiclesLoading } = useListVehicles({
    page: page,
    limit: pageSize,
    search: searchTerm ?? '',
  })

  const truncate = (str: string, length: number) => (str.length > length ? `${str.substring(0, length)}...` : str)

  const vehicleRows: VehicleRow[] =
    data?.items.map((vehicle) => ({
      name: truncate(vehicle.name ?? 'N/A', 15),
      id: vehicle.id ?? 'N/A',
      ymm: truncate(`${vehicle.year ?? ''} ${vehicle.make ?? ''} ${vehicle.model ?? ''}`, 30),
      vin: truncate(vehicle.vin ?? 'N/A', 17),
      consumer: truncate(vehicle.consumer.name ?? '', 15),
    })) || []

  const selectedRow = vehicleRows.find((vehicle) => vehicle.id === selectedVehicleId)

  const dataTableColumns: DataTableColumn<VehicleRow>[] = columns.map((column) => {
    switch (column) {
      case 'name':
        return {
          accessor: 'name',
          title: 'Vehicle Name',
          ellipsis: true,
        }
      case 'consumer':
        return {
          accessor: 'consumer',
          title: 'Consumer',
          ellipsis: true,
        }
      case 'ymm':
        return {
          accessor: 'ymm',
          title: 'Year - Make - Model',
          ellipsis: true,
        }
      case 'vin':
        return {
          accessor: 'vin',
          title: 'VIN',
          ellipsis: true,
        }
      default:
        throw new Error(`Unknown column: ${column}`)
    }
  })

  return (
    <Table.ScrollContainer minWidth="0">
      <DataTable
        minHeight={300}
        striped
        highlightOnHover
        withTableBorder
        borderRadius="md"
        horizontalSpacing="md"
        verticalSpacing="sm"
        noRecordsText="No vehicles to display"
        loadingText="Loading..."
        records={vehicleRows}
        fetching={listVehiclesLoading}
        loaderSize={'lg'}
        loaderBackgroundBlur={3}
        noRecordsIcon={<IconCarFilled size={36} strokeWidth={1.5} />}
        columns={dataTableColumns}
        page={page}
        paginationSize="sm"
        paginationText={({ from, to, totalRecords }) => `${from} - ${to} out of ${totalRecords} vehicles`}
        onPageChange={(nextPage) => setPage(nextPage)}
        totalRecords={data?.count || 0}
        recordsPerPage={pageSize}
        onRowClick={(event) => {
          const clickedVehicleId = event.record.id
          onSelectedVehicleIdChange(clickedVehicleId === selectedVehicleId ? null : clickedVehicleId)
        }}
        selectedRecords={selectedRow ? [selectedRow] : []}
        onSelectedRecordsChange={(rows) => {
          if (rows) {
            onSelectedVehicleIdChange(rows.find((row) => row.id !== selectedVehicleId)?.id ?? null)
          } else {
            onSelectedVehicleIdChange(null)
          }
        }}
        allRecordsSelectionCheckboxProps={{ style: { display: 'none' } }}
        recordsPerPageOptions={[initialRecordsPerPage, initialRecordsPerPage * 2, initialRecordsPerPage * 3]}
        onRecordsPerPageChange={setPageSize}
      />
    </Table.ScrollContainer>
  )
}

/** Step in manual mobile service to select a vehicle */
const VehicleSelect = ({
  onBack,
  onNext,
  vehicleId,
}: {
  vehicleId?: string
  onBack?: () => void
  onNext: (vehicleId: string) => void
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 100)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<VehicleSelectSchema>({
    defaultValues: { vehicleId },
    resolver: zodResolver(vehicleSelectSchema),
  })

  const selectedVehicleId = watch('vehicleId')

  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleSubmit((data) => onNext(data.vehicleId))()}
      backDisabled
      nextDisabled={!selectedVehicleId || !isValid}
      title="Select a vehicle for service">
      <ScrollArea w={'100vw'} h="100%" hiddenFrom="xs">
        <Stack gap="md" pl="md">
          <ErrorMessage errors={errors} name="vehicleId" />
          <Controller
            control={control}
            name="vehicleId"
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <Group justify="space-between" align="end">
                    <Box maw={500} w={'max-content'}>
                      <SelectedVehicleCard vehicleId={value} />
                    </Box>
                    <TextInput
                      placeholder="Search for a vehicle"
                      leftSection={<IconSearch />}
                      size="lg"
                      value={searchTerm}
                      onChange={(event) => {
                        setSearchTerm(event.currentTarget.value)
                      }}
                    />
                  </Group>
                  <VehicleTable
                    recordsPerPage={10}
                    selectedVehicleId={value}
                    onSelectedVehicleIdChange={onChange}
                    columns={['name', 'consumer', 'ymm', 'vin']}
                    searchTerm={debouncedSearchTerm}
                  />
                </>
              )
            }}
          />
        </Stack>
      </ScrollArea>
      <Stack w={1100} gap="md" visibleFrom="xs">
        <ErrorMessage errors={errors} name="vehicleId" />
        <Controller
          control={control}
          name="vehicleId"
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Group justify="space-between" align="end">
                  <Box maw={500} w={'max-content'}>
                    <SelectedVehicleCard vehicleId={value} />
                  </Box>
                  <TextInput
                    placeholder="Search for a vehicle"
                    leftSection={<IconSearch />}
                    size="lg"
                    value={searchTerm}
                    onChange={(event) => {
                      setSearchTerm(event.currentTarget.value)
                    }}
                  />
                </Group>
                <VehicleTable
                  recordsPerPage={10}
                  selectedVehicleId={value}
                  onSelectedVehicleIdChange={onChange}
                  columns={['name', 'consumer', 'ymm', 'vin']}
                  searchTerm={debouncedSearchTerm}
                />
              </>
            )
          }}
        />
      </Stack>
    </StepContainer>
  )
}

export default VehicleSelect
