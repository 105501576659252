import { Center, Stack, Title } from '@mantine/core'

import BottomBar, { BottomBarProps } from './BottomBar'

const StepContainer = (props: BottomBarProps & { children: React.ReactNode; title: string }) => {
  const { children, ...rest } = props
  return (
    <Stack h="100%">
      <Stack h="100%">
        <Center p="xl" pt="md">
          <Stack w={{ base: '100%', xs: 1100 }} gap="md" align="center">
            <Title order={2}>{props.title}</Title>
            {children}
          </Stack>
        </Center>
      </Stack>
      <BottomBar {...rest} />
    </Stack>
  )
}

export default StepContainer
