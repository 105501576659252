import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Table, TextInput } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { DataTable, DataTableColumn } from 'mantine-datatable'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { apiCall } from '../../../components/api/api'
import StepContainer from '../components/StepContainer'
import { BaseProviderBranchSchema } from '../models'

const ProviderBranchSchema = BaseProviderBranchSchema.extend({
  metro_area: z.object({
    id: z.string(),
    name: z.string(),
  }),
})

const ProviderBranchListSchema = z.object({
  items: z.array(ProviderBranchSchema),
  count: z.number(),
})

type ProviderBranch = z.infer<typeof ProviderBranchSchema>

export const providerSelectSchema = z.object({
  providerBranchId: z.string({ message: 'please select a provider branch' }).min(1),
})
type ProviderSelectSchema = z.infer<typeof providerSelectSchema>

const ProviderBranchTable = ({
  selectedBranchId,
  onBranchSelect,
}: {
  selectedBranchId: string | undefined
  onBranchSelect: (providerBranchId: string | null) => void
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearch] = useDebouncedValue(searchTerm, 300)
  const [page, setPage] = useState(1)
  const pageSize = 8

  const { data, isLoading } = useQuery({
    queryKey: ['provider-branches', { page, limit: pageSize, search: debouncedSearch }],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        page: page.toString(),
        limit: pageSize.toString(),
        search: debouncedSearch,
      })

      const data = await apiCall(`v2/admin/provider/branch?${searchParams.toString()}`, 'get')
      const { data: branchListData, error } = ProviderBranchListSchema.safeParse(data)

      if (error) {
        throw new Error(JSON.stringify(error))
      }
      return branchListData
    },
  })

  const branches: ProviderBranch[] = data?.items || []
  const selectedBranch = branches.find((b) => b.id === selectedBranchId)

  const columns: DataTableColumn<ProviderBranch>[] = [
    { accessor: 'name', title: 'Branch Name' },
    { accessor: 'address', title: 'Address' },
    {
      accessor: 'metro_area.name',
      title: 'Metro Area',
      render: (record) => record.metro_area.name,
    },
  ]

  return (
    <Box>
      <TextInput
        placeholder="Search provider branch"
        leftSection={<IconSearch />}
        size="lg"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        mb="sm"
      />
      <Table.ScrollContainer minWidth={0}>
        <DataTable
          minHeight={300}
          striped
          highlightOnHover
          withTableBorder
          borderRadius="md"
          horizontalSpacing="md"
          verticalSpacing="sm"
          records={branches}
          columns={columns}
          onRowClick={(event) => {
            const clickedBranchId = event.record.id
            onBranchSelect(clickedBranchId === selectedBranchId ? null : clickedBranchId)
          }}
          selectedRecords={selectedBranch ? [selectedBranch] : []}
          onSelectedRecordsChange={(rows) => {
            if (rows) {
              onBranchSelect(rows.find((row) => row.id !== selectedBranchId)?.id ?? null)
            } else {
              onBranchSelect(null)
            }
          }}
          allRecordsSelectionCheckboxProps={{ style: { display: 'none' } }}
          fetching={isLoading}
          page={page}
          onPageChange={setPage}
          totalRecords={data?.count || 0}
          recordsPerPage={pageSize}
          noRecordsText="No branches found"
        />
      </Table.ScrollContainer>
    </Box>
  )
}

const ProviderSelect = ({
  onBack,
  onNext,
  providerBranchId,
}: {
  providerBranchId: string | undefined
  onBack?: () => void
  onNext: (providerBranchId: string) => void
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<ProviderSelectSchema>({
    defaultValues: { providerBranchId },
    resolver: zodResolver(providerSelectSchema),
  })

  const selectedProviderBranchId = watch('providerBranchId')
  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleSubmit((data) => onNext(data.providerBranchId))()}
      nextDisabled={!selectedProviderBranchId || !isValid}
      title="Select a service provider branch">
      <Controller
        control={control}
        name="providerBranchId"
        render={({ field: { onChange, value } }) => (
          <ProviderBranchTable selectedBranchId={value} onBranchSelect={onChange} />
        )}
      />
    </StepContainer>
  )
}

export default ProviderSelect
