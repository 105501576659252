import './index.css'
import '@mantine/core/styles.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/carousel/styles.css'
import '@mantine/dates/styles.css'

import { createTheme, MantineProvider } from '@mantine/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Login from './pages/auth/login/login'
import { store } from './redux/store'
import { MainRoute } from './routes/MainRoute'

const theme = createTheme({
  shadows: {
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    xxxl: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  },
  colors: {
    orange: [
      '#fff1e2',
      '#ffe2cc',
      '#ffc39a',
      '#ffa264',
      '#fe8737',
      '#fe751a',
      '#ff6c09',
      '#e45a00',
      '#cb4f00',
      '#b14200',
    ],
    success: [
      '#E8F5E9',
      '#C8E6C9',
      '#A5D6A7',
      '#81C784',
      '#66BB6A',
      '#4CAF50',
      '#43A047',
      '#388E3C',
      '#2E7D32',
      '#1B5E20',
    ],
  },
  fontFamily: 'FigTree',
  headings: {
    sizes: {
      h1: {
        fontSize: '36px',
        lineHeight: '44px',
        fontWeight: '600',
      },
    },
  },
  primaryColor: 'orange',
  components: {
    NumberInput: {
      defaultProps: {
        thousandSeparator: ',',
      },
    },
    NumberFormatter: {
      defaultProps: {
        thousandSeparator: true,
      },
    },
    LoadingOverlay: {
      defaultProps: {
        zIndex: 200,
      },
    },
  },
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" /> */}
      <MantineProvider theme={theme}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/*" element={<MainRoute />} />
            </Routes>
          </BrowserRouter>
        </ReduxProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
