import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  CopyButton,
  Group,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { IconAlertCircle, IconCheck, IconCopy, IconPhoto, IconUpload, IconX } from '@tabler/icons-react'
import { useState } from 'react'

import { apiCall } from '../../../components/api/api'

const ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/heic',
  'image/heif',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'text/csv',
]

const CreateFileLinkPage: React.FC = () => {
  const [file, setFile] = useState<File | null>(null)
  const [s3Link, setS3Link] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorDetails, setErrorDetails] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleDrop = (files: File[]) => {
    setFile(files[0])
    setErrorMessage(null)
    setErrorDetails(null)
    setS3Link(null)
  }

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file first')
      return
    }

    // Validate file type
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    const isValidType =
      ALLOWED_MIME_TYPES.includes(file.type) ||
      ['jpg', 'jpeg', 'png', 'heic', 'heif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'csv'].includes(
        fileExtension || '',
      )

    if (!isValidType) {
      setErrorMessage('Invalid file type')
      setErrorDetails('Supported formats: jpg, jpeg, png, heic, heif, pdf, doc, docx, xls, xlsx, txt, csv')
      return
    }

    setIsLoading(true)
    setErrorMessage(null)
    setErrorDetails(null)
    setS3Link(null)

    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await apiCall('/v2/admin/file/upload', 'post', formData, undefined, true)

      if (response && response.fileUrl) {
        setS3Link(response.fileUrl)
        setFile(null) // Clear the file after successful upload
      } else {
        throw new Error('Invalid response from server. Please contact the engineering team.')
      }
    } catch (error: any) {
      setErrorMessage('Failed to upload file')
      const detailedError =
        error.response?.data?.message || error.message || 'Server error. Please contact the engineering team.'
      setErrorDetails(detailedError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Center h="100vh">
      <Stack gap="20px" w={{ base: '80%', sm: '60%', lg: '50%' }} pb="50px">
        <Text size="xl" fw={700} ta="center">
          Create File Link Tool
        </Text>
        <Alert color="blue" title="File Upload Instructions" icon={<IconAlertCircle size={16} />}>
          Upload a file to generate a shareable S3 link. <br></br>Supported formats: jpg, jpeg, png, heic, heif, pdf,
          doc, docx, xls, xlsx, txt, csv.
        </Alert>

        <Box style={{ border: '2px dashed #ced4da', borderRadius: '8px', padding: '4px' }}>
          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => {
              setErrorMessage('File upload rejected')
              setErrorDetails('The file may be too large or of an unsupported format')
              console.error('rejected files', files)
            }}
            multiple={false}
            loading={isLoading}
            accept={ALLOWED_MIME_TYPES}
            maxSize={10 * 1024 * 1024} // 10MB max size
          >
            <Group justify="center" gap="xl" mih={200} style={{ pointerEvents: 'none' }}>
              <Dropzone.Accept>
                <IconUpload size={64} color="var(--mantine-color-blue-6)" stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={64} color="var(--mantine-color-red-6)" stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size={64} color="var(--mantine-color-dimmed)" stroke={1.5} />
              </Dropzone.Idle>

              <div style={{ maxWidth: '100%' }}>
                <Text size="xl" fz="1.5rem" inline style={{ wordBreak: 'break-word' }}>
                  {file ? `Selected file: ${file.name}` : 'Drag file here or click to select'}
                </Text>
                <Text size="md" c="dimmed" inline mt={7}>
                  Upload a supported file to get an S3 link
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Box>

        <Button onClick={handleUpload} loading={isLoading} disabled={!file || isLoading} size="md">
          Upload File
        </Button>

        {errorMessage && (
          <Alert color="red" title={errorMessage} icon={<IconAlertCircle size={16} />}>
            {errorDetails}
          </Alert>
        )}

        {s3Link && (
          <TextInput
            value={s3Link}
            readOnly
            label="Generated S3 Link"
            styles={{ input: { wordBreak: 'break-all' } }}
            rightSection={
              <CopyButton value={s3Link} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                    <ActionIcon color={copied ? 'teal' : 'orange'} variant="subtle" onClick={copy}>
                      {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            }
          />
        )}
      </Stack>
    </Center>
  )
}

export default CreateFileLinkPage
