import { AppShell, Burger } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React from 'react'

export type SidebarLayoutProps = {
  sidebar: React.ReactNode | ((props: { onLinkClick: () => void }) => React.ReactNode)
  children: React.ReactNode
}

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ sidebar, children }) => {
  const [opened, { toggle }] = useDisclosure()

  const handleLinkClick = () => {
    if (opened) {
      toggle()
    }
  }

  return (
    <AppShell
      h={{ base: '100dvh', xs: '100vh' }}
      header={{ height: { base: 60, xs: 0 } }}
      navbar={{
        width: { base: '100%', xs: 230 },
        breakpoint: 'xs',
        collapsed: { mobile: !opened },
      }}>
      <AppShell.Header p="md" hiddenFrom="xs">
        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />
      </AppShell.Header>
      <AppShell.Navbar h="100%">
        {typeof sidebar === 'function' ? sidebar({ onLinkClick: handleLinkClick }) : sidebar}
      </AppShell.Navbar>
      <AppShell.Main
        style={{
          overflow: 'auto',
          height: '100%',
        }}>
        {children}
      </AppShell.Main>
    </AppShell>
  )
}
