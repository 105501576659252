import { SnackbarProvider } from 'notistack'
import { Route, Routes } from 'react-router-dom'

import { SidebarLayout } from '../components/layouts/SidebarLayout'
import { NewSideBar } from '../components/navbar/NewSideBar'
import InvoiceReviewPage from '../pages/invoice_review/invoice_review'
import RequestsPage from '../pages/requests/requests'
import ServiceHistoryUploadPage from '../pages/service_history_upload/service_history_upload'
import ServiceReviewPage from '../pages/service_review/service_review'
import CreateFileLinkPage from '../pages/tools/create_file_link/CreateFileLink'
import ExtendRequestExpiryPage from '../pages/tools/extend_request_expiry/extend_request_expiry'

export const MainRoute = () => {
  return (
    <SidebarLayout
      sidebar={({ onLinkClick }) => (
        <SnackbarProvider>
          <NewSideBar onLinkClick={onLinkClick} />
        </SnackbarProvider>
      )}>
      <Routes>
        <Route path="/requests" element={<RequestsPage />} />
        <Route path="/service-review" element={<ServiceReviewPage />} />
        <Route path="/invoice-review" element={<InvoiceReviewPage />} />
        <Route path="/service-history-upload" element={<ServiceHistoryUploadPage />} />
        <Route path="/tools/extend-request-expiry" element={<ExtendRequestExpiryPage />} />
        <Route path="/tools/create-file-link" element={<CreateFileLinkPage />} />
      </Routes>
    </SidebarLayout>
  )
}
