import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

interface ApprovalModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({ open, onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm()
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="approval-dialog-title"
      aria-describedby="approval-dialog-description">
      <DialogTitle id="approval-dialog-title" sx={{ mt: 2, ml: 2, mr: 2 }}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 600, color: '#101828' }}>
          Approve invoice and send it to customer?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ ml: 2, mr: 2 }}>
        <Typography gutterBottom id="approval-dialog-description" sx={{ color: '#475467' }}>
          Invoice will be sent to customer for payment.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ mb: 2, ml: 2, mr: 2 }}>
        <Button onClick={handleClose} color="warning" variant="outlined" sx={{ width: '50%' }}>
          Back
        </Button>
        <Button onClick={handleConfirm} color="warning" variant="contained" sx={{ width: '50%' }} disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApprovalModal
