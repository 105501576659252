import { Button, Divider, Group, Modal, Stack, Text } from '@mantine/core'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CarmaLogo from '../../assets/images/CarmaLogo.png'
import { LinksGroup } from './NavbarLinksGroup'
import { UserButton } from './UserButton'

interface MenuItem {
  label: string
  to?: string
  icon: React.FC
  links?: { label: string; to: string }[]
  initiallyOpened?: boolean
}

interface NavbarNestedProps {
  items: MenuItem[]
  userName: string
  userEmail: string
  onLinkClick?: () => void
}

export function NavbarNested({ items, userName, userEmail, onLinkClick }: NavbarNestedProps) {
  const navigate = useNavigate()
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  // handleLogout does not actually log out, just routes to '/'. This prompts a sign in again.
  const handleLogout = async () => {
    try {
      localStorage.removeItem('userToken')
      navigate('/')
    } catch (error) {
      console.error('Failed to logout:', error)
    }
  }

  const handleNavigation = (to: string) => {
    navigate(to)
    onLinkClick?.()
  }

  const links = items.map((item) => (
    <div
      key={item.label}
      onClick={() => item.to && handleNavigation(item.to)}
      style={{ cursor: item.to ? 'pointer' : 'default' }}>
      <LinksGroup {...item} links={item.links || []} onLinkClick={onLinkClick} />
    </div>
  ))

  return (
    <nav
      style={{
        backgroundColor: '#FF6600',
        height: '100%',

        paddingTop: 'var(--mantine-spacing-md)',
        paddingBottom: '0',
        borderRight: '1px solid var(--mantine-color-gray-3)',
      }}>
      <Stack justify="space-between" style={{ height: '100%' }}>
        <Stack gap={0} style={{ paddingLeft: '12px', paddingRight: '12px' }}>
          <div
            style={{
              padding: 'var(--mantine-spacing-md)',
              paddingTop: '0',
              marginLeft: 'calc(var(--mantine-spacing-sm) * -1)',
              marginRight: 'calc(var(--mantine-spacing-sm) * -1)',
              color: 'var(--mantine-color-black)',
            }}>
            <img
              src={CarmaLogo}
              alt="Carma Logo"
              width="135px"
              style={{
                display: 'block',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/requests')
              }}
            />
          </div>

          <div>
            <div style={{ paddingTop: 'var(--mantine-spacing-sm)', paddingBottom: 'var(--mantine-spacing-sm)' }}>
              {links}
            </div>
          </div>
        </Stack>

        <div>
          <Divider style={{ width: '100%' }} />

          <UserButton userName={userName} userEmail={userEmail} onLogout={() => setIsLogoutModalOpen(true)} />
        </div>
      </Stack>

      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onConfirm={() => {
          handleLogout()
          setIsLogoutModalOpen(false)
        }}
      />
    </nav>
  )
}

export const LogoutModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      centered
      title={
        <Text size="lg" style={{ fontWeight: 600 }}>
          Logout
        </Text>
      }>
      <Stack>
        <Text size="sm">Are you sure you want to logout?</Text>
        <Group justify="center" style={{ marginTop: 'sm' }}>
          <Button color="#ff6600" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button color="#ff6600" onClick={onConfirm}>
            Logout
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
