/** Inputs for the Manual Mobile Service Request Offer form
 *
 * Can be used in each step, and also in final validation
 */

import { z } from 'zod'

/** Services applied to the vehicle */
export const ConsumerRequestOfferServiceSchema = z.object({
  service_id: z.string().uuid(),
  service_name: z.string(),
  price: z.number().min(0),
})

export type ConsumerRequestOfferService = z.infer<typeof ConsumerRequestOfferServiceSchema>
export const requestOfferFileTypes = [
  'ESTIMATE',
  'INVOICE',
  'DIAGNOSTIC_FEEDBACK',
  'INSPECTION',
  'PMI',
  'WORK_ORDER_FEEDBACK',
  'WORK_ORDER',
] as const
export type RequestOfferFileType = (typeof requestOfferFileTypes)[number]
export const isRequestOfferFileType = (value: string): value is RequestOfferFileType =>
  requestOfferFileTypes.includes(value as RequestOfferFileType)

export const RequestOfferFileUploadSchema = z.object({
  file: z.instanceof(File),
  type: z.enum(requestOfferFileTypes),
})
export type RequestOfferFileUpload = z.infer<typeof RequestOfferFileUploadSchema>

export const ManualRequestVehicleIdSchema = z
  .string({ message: 'please select a vehicle' })
  .uuid()
  .min(1, 'please select a vehicle')
export const ManualRequestDateSchema = z
  .date({
    required_error: 'Please select a date',
    invalid_type_error: 'Invalid date',
  })
  .refine(
    (date) => {
      const now = new Date()
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
      return date < midnight
    },
    { message: 'Date must not be in the future' },
  )
export const ManualRequestInMileageSchema = z
  .number({
    required_error: 'Please enter the mileage',
    invalid_type_error: 'Mileage must be a number',
  })
  .int()
  .min(1, 'Mileage must be positive')
export const ManualRequestInvoiceNumberSchema = z.string().nullable()
export const ManualRequestSupplyFeeSchema = z
  .number({
    required_error: 'Please enter the supply fee',
    invalid_type_error: 'Supply fee must be a number',
  })
  .min(0, 'Supply fee must be non-negative')
export const ManualRequestTaxSchema = z
  .number({
    required_error: 'Please enter the tax amount',
    invalid_type_error: 'Tax must be a number',
  })
  .min(0, 'Tax must be positive')
export const ManualRequestIsMobileRequestSchema = z.boolean()

export const ManualRequestSchema = z.object({
  vehicleId: ManualRequestVehicleIdSchema,
  providerBranchId: z.string().uuid(),
  date: ManualRequestDateSchema,
  inMileage: ManualRequestInMileageSchema,
  invoiceNumber: ManualRequestInvoiceNumberSchema,
  supplyFee: ManualRequestSupplyFeeSchema,
  tax: ManualRequestTaxSchema,
  isMobileRequest: ManualRequestIsMobileRequestSchema,
  details: z.array(ConsumerRequestOfferServiceSchema),
  files: z.array(RequestOfferFileUploadSchema),
})

/** Services stored in Mobile Service Request Offer state */
export type ManualRequest = z.infer<typeof ManualRequestSchema>
