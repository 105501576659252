import { Checkbox } from '@mantine/core'
import { Box, Button, Container, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import { VerificationItem } from '../models/diagnostic_report'

const initialItemsDiagnostic: VerificationItem[] = [
  {
    key: 'Are the diagnostics complete and address the customer’s concern?',
    response: false,
    comment: '',
  },
  {
    key: 'Are there any unnecessary services that are being recommended outside of the scope of the diagnosed issue?',
    response: false,
    comment: '',
  },
  {
    key: 'Are all labor hours for each recommended service item in line with AllData (Motor) reference book times?',
    response: false,
    comment: '',
  },
  {
    key: 'Are all parts prices for each recommended service item within a reasonable markup (max 50% for all parts except: max 35% for new batteries, max 20% for new tires) from the MSRP?',
    response: false,
    comment: '',
  },
  {
    key: 'Does the total on the work order match what is entered in Carma?',
    response: false,
    comment: '',
  },
]

const initialItemsInspection: VerificationItem[] = [
  {
    key: 'Is the vehicle inspection complete?',
    response: false,
    comment: '',
  },
  {
    key: 'Are there any unnecessary services that are being recommended?',
    response: false,
    comment: '',
  },
  {
    key: 'Are all labor hours for each recommended service item in line with AllData (Motor) reference book times?',
    response: false,
    comment: '',
  },
  {
    key: 'Are all parts prices for each recommended service item within a reasonable markup (max 50% for all parts except: max 35% for new batteries, max 20% for new tires) from the MSRP?',
    response: false,
    comment: '',
  },
  {
    key: 'Does the total on the work order match what is entered in Carma?',
    response: false,
    comment: '',
  },
]

interface FeedbackForShopModalProps {
  offer: any
  open: boolean
  onClose: () => void
  onConfirm: (items: VerificationItem[], instructions: string) => void
}

const FeedbackForShopModal: React.FC<FeedbackForShopModalProps> = ({ offer, open, onClose, onConfirm }) => {
  const [items, setItems] = useState(offer.type === 'WORK_ORDER' ? initialItemsInspection : initialItemsDiagnostic)
  const [instructions, setInstructions] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm(items, instructions)
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  const handleCheckChange = (index: number) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, response: !item.response, comment: '' }
      }
      return item
    })
    setItems(newItems)
  }

  const handleCommentChange = (index: number, comment: string) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, comment }
      }
      return item
    })
    setItems(newItems)
  }

  const isFormValid = () => {
    const hasInstructions = instructions.trim().length > 0
    const allItemsValid = items.every((item) => item.response !== item.comment.trim().length > 0)
    return hasInstructions && allItemsValid
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="checklist-modal-title"
      aria-describedby="checklist-modal-description">
      <div>
        <Container
          maxWidth="md"
          sx={{
            marginTop: '10vh',
            marginBottom: '10vh',
            width: '100%',
            maxWidth: 'calc(100% - 40px)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            display: { xs: 'flex', sm: 'none' },
            flexDirection: 'column',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}>
          <Typography id="checklist-modal-title" variant="h6" sx={{ mb: 1 }}>
            {offer.type === 'WORK_ORDER' ? 'Inspection Checklist' : 'Diagnostic Checklist'}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 1, color: '#667085' }}>
            <Grid item xs={12} sm={5}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Key Verification Point</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Response</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Technician Comments</Typography>
            </Grid>
          </Grid>
          {items?.map((item, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 0.5 }}>
              <Grid item xs={12} sm={5}>
                <Typography>{item.key}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Checkbox p={8} size="lg" checked={item.response} onClick={() => handleCheckChange(index)} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  multiline
                  placeholder="Write your comments here..."
                  value={item.comment}
                  onChange={(e) => handleCommentChange(index, e.target.value)}
                  variant="outlined"
                  color="warning"
                  sx={{ height: '100px' }}
                  disabled={item.response}
                />
              </Grid>
            </Grid>
          ))}
          <TextField
            fullWidth
            multiline
            label="Instructions for the service center:"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
            color="warning"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              sx={{ mr: 2, width: '50%', fontWeight: 600 }}
              color="warning"
              variant="outlined"
              onClick={handleClose}>
              Back
            </Button>
            <Button
              sx={{ width: '50%', fontWeight: 600 }}
              variant="contained"
              color="warning"
              onClick={handleConfirm}
              disabled={!isFormValid() || isLoading}>
              Send to shop
            </Button>
          </Box>
        </Container>
        <Container
          maxWidth="md"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            maxWidth: 'calc(100% - 40px)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            maxHeight: '95vh',
            overflowY: 'auto',
          }}>
          <Typography id="checklist-modal-title" variant="h6" sx={{ mb: 1 }}>
            {offer.type === 'WORK_ORDER' ? 'Inspection Checklist' : 'Diagnostic Checklist'}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 1, color: '#667085' }}>
            <Grid item xs={12} sm={5}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Key Verification Point</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Response</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography style={{ fontWeight: 600, color: '#ff6600' }}>Technician Comments</Typography>
            </Grid>
          </Grid>
          {items?.map((item, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 0.5 }}>
              <Grid item xs={12} sm={5}>
                <Typography>{item.key}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Checkbox p={8} size="lg" checked={item.response} onClick={() => handleCheckChange(index)} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  multiline
                  placeholder="Write your comments here..."
                  value={item.comment}
                  onChange={(e) => handleCommentChange(index, e.target.value)}
                  variant="outlined"
                  color="warning"
                  sx={{ height: '100px' }}
                  disabled={item.response}
                />
              </Grid>
            </Grid>
          ))}
          <TextField
            fullWidth
            multiline
            label="Instructions for the service center:"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
            color="warning"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              sx={{ mr: 2, width: '50%', fontWeight: 600 }}
              color="warning"
              variant="outlined"
              onClick={handleClose}>
              Back
            </Button>
            <Button
              sx={{ width: '50%', fontWeight: 600 }}
              variant="contained"
              color="warning"
              onClick={handleConfirm}
              disabled={!isFormValid() || isLoading}>
              Send to shop
            </Button>
          </Box>
        </Container>
      </div>
    </Modal>
  )
}

export default FeedbackForShopModal
