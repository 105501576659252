import { Box, Collapse, Group, Text, ThemeIcon, UnstyledButton } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface LinksGroupProps {
  icon: React.FC<any>
  label: string
  initiallyOpened?: boolean
  links: { label: string; to: string }[]
  onLinkClick?: () => void
}

export function LinksGroup({ icon: Icon, label, initiallyOpened, links, onLinkClick }: LinksGroupProps) {
  const navigate = useNavigate()
  const hasLinks = Array.isArray(links) && links.length > 0
  const [opened, setOpened] = useState(initiallyOpened || false)

  const handleNavigation = (to: string) => {
    navigate(to)
    onLinkClick?.()
  }

  const items = (hasLinks ? links : []).map((link) => (
    <Text
      onClick={() => handleNavigation(link.to)}
      key={link.label}
      style={{
        cursor: 'pointer',
        fontWeight: 600,
        display: 'block',
        textDecoration: 'none',
        padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-md)',
        paddingLeft: 'var(--mantine-spacing-md)',
        marginLeft: 'var(--mantine-spacing-xl)',
        fontSize: 'var(--mantine-font-size-sm)',
        color: '#ffffff',
        borderLeft: '1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))',
      }}>
      {link.label}
    </Text>
  ))

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        style={{
          fontWeight: 700,
          display: 'block',
          width: '100%',
          padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-md)',
          color: '#ffffff',
          fontSize: 'var(--mantine-font-size-sm)',
        }}>
        <Group justify="space-between" gap={0}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant="light" color="white" size={30}>
              <Icon size={18} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && (
            <IconChevronRight
              className="chevron"
              stroke={1.5}
              size={18}
              style={{
                transform: opened ? 'rotate(-90deg)' : 'none',
                transition: 'transform 200ms ease',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  )
}
