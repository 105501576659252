import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { apiCall } from '../../../components/api/api'
import { BaseVehicle, BaseVehicleSchema } from '../models'

const VehicleListSchema = z.object({
  items: z.array(BaseVehicleSchema),
  count: z.number(),
})

type VehicleListData = {
  items: BaseVehicle[]
  count: number
}

export const useVehicle = (vehicleId?: string) => {
  return useQuery<BaseVehicle>({
    queryKey: ['vehicles', 'detail', vehicleId],
    queryFn: async () => {
      if (!vehicleId) {
        throw new Error('Vehicle ID is required')
      }
      const data = await apiCall(`admin/consumer/vehicle/${vehicleId}`, 'get')
      const { data: vehicleData, error } = BaseVehicleSchema.safeParse(data)
      if (error) {
        throw new Error(JSON.stringify(error))
      }
      return vehicleData
    },
    staleTime: 60000,
  })
}

export const useListVehicles = (params: { page: number; limit: number; search: string }) => {
  return useQuery<VehicleListData>({
    queryKey: ['vehicles', 'list', params],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        page: params.page.toString(),
        limit: params.limit.toString(),
        search: params.search,
      })

      const data = await apiCall(`admin/consumer/vehicle?${searchParams.toString()}`, 'get')
      const { data: vehicleListData, error } = VehicleListSchema.safeParse(data)

      console.log('Error parsing vehicle list data:', JSON.stringify(error, null, 2))

      if (error) {
        throw new Error(JSON.stringify(error))
      }
      return vehicleListData
    },
    staleTime: 60000,
  })
}
