import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const apiCall = async (
  url: string,
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  data?: any,
  params?: any,
  isFileUpload?: boolean,
) => {
  const userToken = localStorage.getItem('userToken')
  try {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${userToken}`,
    }

    if (isFileUpload) {
      headers['Content-Type'] = 'multipart/form-data'
    } else {
      headers['Content-Type'] = 'application/json'
    }

    const response = await api({
      url,
      method,
      data,
      params,
      headers,
    })
    return response.data
  } catch (error: any) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location.href = '/'
    }
    throw error
  }
}
