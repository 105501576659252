import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Card, Group, NumberFormatter, NumberInput, Stack, Text, Title } from '@mantine/core'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import Decimal from 'decimal.js'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { apiCall } from '../../../components/api/api'
import { SelectedVehicleCard } from '../components/SelectedVehicleCard'
import StepContainer from '../components/StepContainer'
import { ManualRequest, ManualRequestSchema } from '../formTypes'
import { BaseProviderBranchSchema, BaseServiceSchema } from '../models'

const ManualRequestConfirm = ({
  onBack,
  onNext,
  state,
}: {
  onBack: () => void
  onNext: (permalink: string) => void
  state: ManualRequest
}) => {
  const { enqueueSnackbar } = useSnackbar()

  // Fetch provider branch details
  const { data: providerBranch } = useQuery({
    queryKey: ['provider-branches', 'detail', state.providerBranchId],
    queryFn: async () => {
      const data = await apiCall(`/v2/admin/provider/branch/${state.providerBranchId}`, 'get')
      const { data: providerBranchData, error } = BaseProviderBranchSchema.safeParse(data)
      if (error) {
        console.error('Error parsing provider branch', error)
        throw new Error(JSON.stringify(error))
      }
      return providerBranchData
    },
  })

  const servicesData = useQueries({
    queries: state.details.map((detail) => ({
      queryKey: ['services', 'detail', detail.service_id],
      queryFn: async () => {
        const data = await apiCall(`/service/v2/${detail.service_id}`, 'get')
        const { data: serviceData, error } = BaseServiceSchema.safeParse(data)
        if (error) {
          console.error('Error parsing service', error)
          throw new Error(JSON.stringify(error))
        }
        return serviceData
      },
    })),
  })

  const servicesTotal = state.details.reduce((sum, service) => sum.plus(service.price), new Decimal(0))
  const expectedTotal = servicesTotal.plus(state.supplyFee).plus(state.tax).toNumber()

  const totalInputSchema = z.object({
    totalInput: z
      .number()
      .min(expectedTotal, `Total must equal expected total: ${expectedTotal.toLocaleString()}`)
      .max(expectedTotal, `Total must equal expected total: ${expectedTotal.toLocaleString()}`),
  })

  const { control, handleSubmit: handleFormSubmit } = useForm({
    resolver: zodResolver(totalInputSchema),
  })

  const mutation = useMutation({
    onError: (e) => {
      if (e instanceof AxiosError) {
        console.error('Axios error', e.response?.data)

        const errorMessage = e.response?.data.message
          ? Array.isArray(e.response?.data.message)
            ? JSON.stringify(e.response?.data.message)
            : e.response.data.message
          : 'An unexpected axios error occurred'
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      } else if (e instanceof Error) {
        console.error('Request error', e.message)
        enqueueSnackbar(e.message, {
          variant: 'error',
        })
      } else {
        console.error('Unexpected request error', e)
        enqueueSnackbar('An unexpected error occurred', {
          variant: 'error',
        })
      }
    },
    mutationFn: async (requestData: ManualRequest & { total: number }) => {
      // Construct POST request
      const formData = new FormData()
      for (const file of requestData.files) {
        formData.append('files', file.file)
      }

      formData.append(
        'files_meta',
        JSON.stringify(
          requestData.files.map((file) => ({
            name: file.file.name,
            type: file.type,
          })),
        ),
      )

      formData.append('vehicle_id', requestData.vehicleId)
      formData.append('provider_branch_id', requestData.providerBranchId)
      formData.append('date', requestData.date.toISOString())
      if (requestData.inMileage) {
        formData.append('in_mileage', requestData.inMileage.toString())
      }
      if (requestData.invoiceNumber) {
        formData.append('invoice_number', requestData.invoiceNumber)
      }
      formData.append('supply_fee', requestData.supplyFee.toString())
      formData.append('tax', requestData.tax.toString())
      formData.append('is_mobile_request', requestData.isMobileRequest.toString())
      formData.append('total', requestData.total.toString())

      formData.append(
        'service_details',
        JSON.stringify(
          // Only need the id & price
          requestData.details.map((detail) => ({
            service_id: detail.service_id,
            price: detail.price,
          })),
        ),
      )

      const userToken = localStorage.getItem('userToken')
      const result = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/consumer-request/create-request-and-offer`,

        formData,
        {
          headers: {
            'Content-Type': `'multipart/form-data'`,
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
      return result.data
    },
    onSuccess: (data) => {
      enqueueSnackbar('Request offer created successfully', { variant: 'success' })
      onNext(data.permalink)
    },
  })

  const handleSubmit = () => {
    handleFormSubmit(
      (data) => {
        try {
          const requestData = ManualRequestSchema.parse(state)
          mutation.mutate({ ...requestData, total: data.totalInput })
        } catch (e) {
          if (e instanceof Error) {
            console.error('Invalid final input', e.message)
            enqueueSnackbar(e.message, { variant: 'error' })
          } else {
            console.error('Unexpected error', e)
            enqueueSnackbar('An unexpected error occurred', { variant: 'error' })
          }
        }
      },
      (error) => {
        console.error('form error', error)
        enqueueSnackbar(error.totalInput?.message?.toString() ?? 'An unexpected error occured', {
          variant: 'error',
        })
      },
    )()
  }

  return (
    <StepContainer
      onBack={onBack}
      onNext={handleSubmit}
      nextDisabled={mutation.isPending}
      nextText="Submit"
      title="Review service details">
      <SelectedVehicleCard vehicleId={state.vehicleId} />

      <Card withBorder w={700}>
        <Stack gap="xs">
          <Title order={4}>Service Provider</Title>
          <Text>{providerBranch?.name}</Text>
          <Text size="sm" c="dimmed">
            {providerBranch?.address}
          </Text>
        </Stack>
      </Card>

      <Card withBorder w={700}>
        <Stack gap="xs">
          <Title order={4}>Service Details</Title>
          <Group justify="space-between">
            <Text>Date</Text>
            <Text>{state.date.toLocaleDateString()}</Text>
          </Group>
          <Group justify="space-between">
            <Text>Mileage</Text>
            <Text>{state.inMileage.toLocaleString()}</Text>
          </Group>
          <Group justify="space-between">
            <Text>Invoice Number</Text>
            <Text>{state.invoiceNumber || 'N/A'}</Text>
          </Group>
          <Group justify="space-between">
            <Text>Supply Fee</Text>
            <Text>
              <NumberFormatter
                prefix="$"
                decimalScale={2}
                thousandSeparator
                fixedDecimalScale
                value={state.supplyFee}
              />
            </Text>
          </Group>
          <Group justify="space-between">
            <Text>Tax</Text>
            <Text>
              <NumberFormatter prefix="$" decimalScale={2} thousandSeparator fixedDecimalScale value={state.tax} />
            </Text>
          </Group>
        </Stack>
      </Card>

      <Card withBorder w={700}>
        <Stack gap="xs">
          <Title order={4}>Services</Title>
          {state.details.map((service, index) => (
            <Group key={index} justify="space-between">
              <Text>{servicesData?.[index]?.data?.name || 'Service not found'}</Text>
              <Text>
                <NumberFormatter
                  prefix="$"
                  decimalScale={2}
                  thousandSeparator
                  fixedDecimalScale
                  value={service.price}
                />
              </Text>
            </Group>
          ))}
          <Box pt="sm">
            <Group justify="space-between" pt="sm">
              <Text fw={600}>Subtotal</Text>
              <Text fw={600}>
                <NumberFormatter
                  prefix="$"
                  decimalScale={2}
                  thousandSeparator
                  fixedDecimalScale
                  value={servicesTotal.toNumber()}
                />
              </Text>
            </Group>
          </Box>
        </Stack>
      </Card>

      <Card withBorder w={700}>
        <Stack gap="xs">
          <Title order={4}>Uploaded Files</Title>
          {state.files.map((file, index) => (
            <Group key={index} justify="space-between">
              <Text>{file.file.name}</Text>
              <Text c="dimmed">{file.type}</Text>
            </Group>
          ))}
          {state.files.length === 0 && (
            <Text c="dimmed" fs="italic">
              No files uploaded
            </Text>
          )}
        </Stack>
      </Card>
      <Card withBorder w={700}>
        <Stack gap="xs">
          <Title order={4}>Confirm the total service cost</Title>
          <Controller
            name="totalInput"
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                withAsterisk
                label="Enter in the total cost of the invoice."
                placeholder={expectedTotal.toLocaleString()}
                onChange={(value) => field.onChange(value)}
                value={field.value}
                required
              />
            )}
          />
        </Stack>
      </Card>
    </StepContainer>
  )
}

export default ManualRequestConfirm
