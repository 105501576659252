import { Box, Button, Center, Container, Stack, Text, TextInput } from '@mantine/core'
import { useState } from 'react'

import { apiCall } from '../../../components/api/api'

const ExtendRequestExpiryPage: React.FC = () => {
  const [requestId, setRequestId] = useState('')
  const [expiryTime, setExpiryTime] = useState<Date | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const extendExpiry = async () => {
    setExpiryTime(null)
    if (requestId.length === 0) {
      setErrorMessage('Please input a request ID')
      return
    }
    const token = localStorage.getItem('userToken')
    if (token) {
      setIsLoading(true) // Set loading to true when the request starts
      try {
        const response = await apiCall(`/v2/admin/consumer-request/${requestId}/extend-request-expiry`, 'patch')

        const newExpiryTime = new Date(response.extendedExpiryTime)
        setExpiryTime(newExpiryTime)

        setErrorMessage(null) // Clear any previous error messages
      } catch (error: any) {
        setErrorMessage(error.response.data.message)
        setExpiryTime(null) // Clear any previous success messages
      } finally {
        setIsLoading(false) // Set loading to false after the request is completed
      }
    }
  }

  return (
    <Center h="100vh">
      <Stack gap="20px" w={{ base: '80%', sm: '50%', lg: '30%' }} pb="50px">
        <TextInput
          label="Request ID"
          placeholder="Enter request ID"
          disabled={isLoading}
          value={requestId}
          onChange={(e) => setRequestId(e.target.value)}
          maxLength={10} // Limit to 10 characters (requestIds are 8 chars)
          size="lg"
        />
        <Button onClick={extendExpiry} loading={isLoading} disabled={isLoading} size="md">
          Extend Expiry by One Hour
        </Button>

        <Box h="60px" /* fixed height to keep message from shifting components */>
          {errorMessage && (
            <Text size="md" c="red" fw="500">
              Error: {errorMessage}
            </Text>
          )}

          {/* Success message displayed as text */}
          {expiryTime && (
            <Text size="md" c="green" fw="500">
              <div>Vehicle now expires at:</div>
              <div>{expiryTime.toString()}</div>
            </Text>
          )}
        </Box>
      </Stack>
    </Center>
  )
}

export default ExtendRequestExpiryPage
