import { z } from 'zod'

export const BaseVehicleSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  vin: z.string(),
  year: z.string(),
  make: z.string(),
  model: z.string(),
  consumer: z.object({
    name: z.string(),
  }),
})

export type BaseVehicle = z.infer<typeof BaseVehicleSchema>

export const BaseProviderBranchSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  address: z.string(),
})

export type BaseProviderBranch = z.infer<typeof BaseProviderBranchSchema>

export const BaseServiceSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export type BaseService = z.infer<typeof BaseServiceSchema>
