import { Button, Group, Stack, Text } from '@mantine/core'

import StepContainer from '../components/StepContainer'

const RequestOfferSuccess = ({
  onBack,
  onRestart,
  completedRequestPermalink,
}: {
  onBack: () => void
  onRestart: () => void
  completedRequestPermalink?: string
}) => {
  return (
    <StepContainer onBack={onBack} nextDisabled={false} title="Request Created">
      <Text>Your request offer has been created.</Text>
      <Group pt="10%">
        <Stack gap="lg">
          <Button size="xl" component="a" href={completedRequestPermalink} target="_blank" rel="noopener noreferrer">
            View Your Request Offer
          </Button>
          <Button size="lg" variant="outline" onClick={onRestart}>
            Submit another request
          </Button>
        </Stack>
      </Group>
    </StepContainer>
  )
}
export default RequestOfferSuccess
