import { Badge, Button, Group, Popover, Progress, rem, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconExclamationCircle } from '@tabler/icons-react'
import { FieldErrors } from 'react-hook-form'

export type BottomBarProps = {
  onBack?: () => void
  onNext?: () => void
  errors?: FieldErrors | Error | null
  nextDisabled?: boolean
  backDisabled?: boolean
  nextText?: string
}

const BottomBar = ({
  onBack,
  onNext = () => {},
  errors,
  nextDisabled = false,
  backDisabled = false,
  nextText = 'Next',
}: BottomBarProps) => {
  const flattenErrors = (errors: FieldErrors | Error | null | undefined) => {
    if (!errors) return {}
    return Object.entries(errors).reduce(
      (acc, [fieldName, error]) => {
        if (!error) return acc
        if (typeof error === 'string') {
          acc[fieldName] = error
        } else if (typeof error.message === 'string') {
          acc[fieldName] = error.message
        } else if (Array.isArray(error)) {
          acc[fieldName] = error.map((e) => e.message).join(', ')
        } else {
          console.error('Unexpected error type', error)
          acc[fieldName] = 'An unexpected error occurred'
        }
        return acc
      },
      {} as Record<string, string>,
    )
  }
  const flattenedErrors = flattenErrors(errors)
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Stack
      gap={0}
      bottom={0}
      left={0}
      right={0}
      bg="white"
      style={{
        zIndex: 1,
        borderTop: '1px solid #gray',
        position: 'sticky',
      }}
      h="80px">
      <Progress value={200} radius={0} size={'sm'} />
      <Group justify="space-between" pt={'md'} pb={'md'} pl={'xl'} pr={'xl'}>
        {onBack ? (
          <Button variant="filled" onClick={onBack} disabled={backDisabled}>
            Back
          </Button>
        ) : (
          <div />
        )}
        <Group>
          {Object.keys(flattenedErrors).length > 0 && (
            <Popover withArrow opened={opened}>
              <Popover.Target>
                <Badge
                  onMouseEnter={open}
                  onMouseLeave={close}
                  color="red"
                  leftSection={<IconExclamationCircle style={{ width: rem(14), height: rem(14) }} />}>
                  {Object.keys(flattenedErrors).length} error
                  {Object.keys(flattenedErrors).length > 1 ? 's' : ''}
                </Badge>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack gap={4}>
                  {Object.entries(flattenedErrors).map(([fieldName, errorMessage]) => (
                    <ErrorItem key={fieldName} fieldName={fieldName} errorMessage={errorMessage} />
                  ))}
                </Stack>
              </Popover.Dropdown>
            </Popover>
          )}
          <Button variant="filled" onClick={onNext} disabled={nextDisabled}>
            {nextText}
          </Button>
        </Group>
      </Group>
    </Stack>
  )
}

const ErrorItem = ({ fieldName, errorMessage }: { fieldName: string; errorMessage: string }) => {
  return (
    <Stack key={fieldName} justify="center">
      <Text size={'sm'}>
        <strong>{fieldName ? `${fieldName}: ` : null}</strong>
        {errorMessage}
      </Text>
    </Stack>
  )
}

export default BottomBar
