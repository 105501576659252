import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, NumberInput, Stack, TextInput } from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import StepContainer from '../components/StepContainer'
import {
  ManualRequestInMileageSchema,
  ManualRequestInvoiceNumberSchema,
  ManualRequestIsMobileRequestSchema,
  ManualRequestSupplyFeeSchema,
  ManualRequestTaxSchema,
} from '../formTypes'

const offerMetaSchema = z.object({
  inMileage: ManualRequestInMileageSchema,
  invoiceNumber: ManualRequestInvoiceNumberSchema,
  supplyFee: ManualRequestSupplyFeeSchema,
  tax: ManualRequestTaxSchema,
  isMobileRequest: ManualRequestIsMobileRequestSchema,
})

type OfferMetaSchema = z.infer<typeof offerMetaSchema>

const OfferMetaSelect = ({
  onBack,
  onNext,
  inMileage,
  invoiceNumber,
  supplyFee,
  tax,
  isMobileRequest,
}: {
  inMileage?: number
  invoiceNumber?: string | null
  supplyFee?: number
  tax?: number
  isMobileRequest?: boolean
  onBack?: () => void
  onNext: (values: OfferMetaSchema) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OfferMetaSchema>({
    defaultValues: {
      inMileage: inMileage || 0,
      invoiceNumber: invoiceNumber || '',
      supplyFee: supplyFee || 0,
      tax: tax || 0,
      isMobileRequest: isMobileRequest || true,
    },
    resolver: zodResolver(offerMetaSchema),
  })

  return (
    <StepContainer
      onBack={onBack}
      onNext={() => handleSubmit((data) => onNext(data))()}
      errors={errors}
      title="Enter service details">
      <Stack align="center" gap="xl">
        <Controller
          control={control}
          name="inMileage"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              size="lg"
              withAsterisk
              label="Vehicle mileage at time of service"
              value={value}
              onChange={(val) => onChange(val)}
              error={errors.inMileage?.message}
              min={0}
              hideControls
              thousandSeparator=","
              w={500}
            />
          )}
        />

        <Controller
          control={control}
          name="invoiceNumber"
          render={({ field }) => (
            <TextInput
              size="lg"
              {...field}
              value={field.value || ''}
              label="Invoice number"
              error={errors.invoiceNumber?.message}
              w={500}
            />
          )}
        />

        <Controller
          control={control}
          name="supplyFee"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              size="lg"
              label="Supply fee"
              value={value}
              onChange={(val) => onChange(val)}
              error={errors.supplyFee?.message}
              min={0}
              decimalScale={2}
              prefix="$"
              hideControls
              w={500}
            />
          )}
        />

        <Controller
          control={control}
          name="tax"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              size="lg"
              label="Tax"
              value={value}
              onChange={(val) => onChange(val)}
              error={errors.tax?.message}
              min={0}
              decimalScale={2}
              prefix="$"
              hideControls
              w={500}
            />
          )}
        />

        <Controller
          control={control}
          name="isMobileRequest"
          render={({ field }) => (
            <Checkbox label="This is a mobile service" checked={field.value} onChange={(val) => field.onChange(val)} />
          )}
        />
      </Stack>
    </StepContainer>
  )
}

export default OfferMetaSelect
